/* eslint-disable react/prop-types */
import React, { useReducer, useMemo } from 'react';
import { HomeContext } from './HomeContext';
import LogginReducer from './HomeReducer';
import { useApi } from '../../Hooks';
import { CHARTS_HOME, HEADER_ANALYTICS_HOME, CHART_DISBURSED } from '../Types';
import { getItem, setItem } from '../../utils/persistentStorage';

const HomeState = (props) => {
  const initialState = {
    chartsHome: null,
    headerAnalityc: null,
    chartDisbursed: null
  };

  const [getDataAnalitycs] = useApi({
    endpoint: 'api/v1/dashboard/clients/',
    method: 'get'
  });
  const [getHeaderAnalitycs] = useApi({
    endpoint: 'api/v1/dashboard/clients/',
    method: 'get'
  });

  const [state, dispatch] = useReducer(LogginReducer, initialState);

  const getAnalitycs = async () => {
    const startFilet = getItem('startDate');
    const endDate = getItem('endDate');

    if (
      getItem('graphics-home') === null ||
      endDate !== null ||
      startFilet !== null
    ) {
      const response = await getDataAnalitycs({
        body: {},
        message: false,
        url: process.env.REACT_APP_BACKEND_URL,
        urlParams: 'all/graphics-home'
      });
      setItem('graphics-home', response.data);
      dispatch({ type: CHARTS_HOME, payload: response.data });
    } else {
      dispatch({ type: CHARTS_HOME, payload: getItem('graphics-home') });
    }

    setTimeout(async () => {
      const responseanalytic = await getHeaderAnalitycs({
        body: {},
        message: false,
        url: process.env.REACT_APP_BACKEND_URL,
        urlParams: 'all/home'
      });
      dispatch({
        type: HEADER_ANALYTICS_HOME,
        payload: responseanalytic.data
      });
    }, 1090);
    setTimeout(async () => {
      if (getItem('graphics-credit-amount') === null) {
        const responseanalytic = await getHeaderAnalitycs({
          body: {},
          message: false,
          url: process.env.REACT_APP_BACKEND_URL,
          urlParams: `all/graphics-credit-amount`
        });
        setItem('graphics-credit-amount', responseanalytic.data);
        dispatch({
          type: CHART_DISBURSED,
          payload: responseanalytic.data
        });
      } else {
        dispatch({
          type: CHART_DISBURSED,
          payload: getItem('graphics-credit-amount')
        });
      }
    }, 1090);
  };

  const filterFinancial = async (financiera, type) => {
    const responseanalytic = await getHeaderAnalitycs({
      body: {},
      message: false,
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: 'all/home'
    });
    dispatch({
      type: HEADER_ANALYTICS_HOME,
      payload: responseanalytic.data
    });
    if (
      type === 'filterFinance' ||
      getItem('graphics-credit-amount') === null
    ) {
      const response = await getDataAnalitycs({
        body: {},
        message: false,
        url: process.env.REACT_APP_BACKEND_URL,
        urlParams: `${financiera}/graphics-home`
      });
      setItem('graphics-home', response.data);

      dispatch({ type: CHARTS_HOME, payload: response.data });
      setTimeout(async () => {
        const responseanalytic = await getHeaderAnalitycs({
          body: {},
          message: false,
          url: process.env.REACT_APP_BACKEND_URL,
          urlParams: `${financiera}/home`
        });
        dispatch({
          type: HEADER_ANALYTICS_HOME,
          payload: responseanalytic.data
        });
      }, 1090);
      setTimeout(async () => {
        const responseanalytic = await getHeaderAnalitycs({
          body: {},
          message: false,
          url: process.env.REACT_APP_BACKEND_URL,
          urlParams: `${financiera}/graphics-credit-amount`
        });
        setItem('graphics-credit-amount', responseanalytic.data);
        dispatch({
          type: CHART_DISBURSED,
          payload: responseanalytic.data
        });
      }, 1050);
    } else {
      dispatch({
        type: CHART_DISBURSED,
        payload: getItem('graphics-credit-amount')
      });
      dispatch({ type: CHARTS_HOME, payload: getItem('graphics-home') });
    }
  };

  const contextValue = useMemo(
    () => ({
      chartsHome: state.chartsHome,
      headerAnalityc: state.headerAnalityc,
      chartDisbursed: state.chartDisbursed,
      getAnalitycs,
      filterFinancial
    }),
    [state]
  );
  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
};

export { HomeState };
