import { LIST_CURSES, LIST_CLIENTS } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case LIST_CURSES:
      return {
        ...state,
        listCurses: payload
      };
    case LIST_CLIENTS:
      return {
        ...state,
        listVideo: payload
      };
    default:
      return state;
  }
};
