/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    padding: '5vh'
  },
  iconButton: {
    backgroundColor: 'white !important',
    margin: '6px !important',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  },
  icondisabled: {
    fontSize: '30px !important',
    color: '#FAD4E1'
  },
  iconstart: {
    fontSize: '30px !important',
    color: theme.palette.primary.main
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '35px !important',
    fontWeight: '800 !important'
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export { useStyles };
