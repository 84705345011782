/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { useStyles } from './ClientsStyles';
import { ClientsContext } from '../../Context/Clients';
import { Filter } from '../Filter';
import { Visibility } from '@mui/icons-material/';
import { getItem } from '../../utils/persistentStorage';
import { ClientDetail } from './ClientDetail';
import { Table } from '../Table';
import { get } from 'lodash';
import { headers, headersOther } from './constans';

const Clients = () => {
  const classes = useStyles();
  const { listclients, getclients, getclientDetail, infoClient } =
    useContext(ClientsContext);
  const [filterData, setfilterData] = useState([]);
  const [detailUser, setdetailUser] = useState(null);
  const filterArray = ['firstName', 'phoneNumber', 'community'];

  const filetContent = async (value) => {
    setfilterData([]);

    if (listclients !== null) {
      if (value === '' || value === null) {
        setfilterData([]);

        setfilterData([...listclients]);
      } else {
        const arrayPushed = [];
        await listclients.map((itemCurse) => {
          filterArray.map((keyObject) => {
            if (itemCurse[keyObject] !== undefined) {
              if (
                itemCurse[keyObject]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                const index = arrayPushed.indexOf(itemCurse);
                if (index === -1) arrayPushed.push(itemCurse);
              }
            }
          });
        });
        setfilterData([...arrayPushed]);
      }
    }
  };

  useEffect(() => {
    listclients !== null && filetContent(getItem('searchValue'));
  }, [listclients]);

  const getInfoclient = async (itemClient) => {
    await setdetailUser(null);
    await setdetailUser(itemClient._id);
    getclientDetail({
      id: itemClient._id,
      finance: itemClient.company_id.name
    });
  };

  const createData = (data) => {
    return data.map((item) => [
      { key: 'customer', content: get(item, 'firstName', ''), type: 'first' },
      { key: 'name', content: get(item, 'lastName', '') },
      { key: 'sales', content: get(item, 'phoneNumber', '') },
      { key: 'distrit', content: get(item, 'user_email', '') },
      { key: 'type', content: get(item, 'community', '') },
      {
        key: 'text',
        content: get(item, ['idMentor', 'email'], ''),
        type: 'latest'
      },
      {
        key: 'date',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              getInfoclient(item);
              window.scroll(0, 0);
            }}
          >
            <Visibility className={classes.icon} />
          </IconButton>
        )
      }
    ]);
  };
  const createSecondData = (data) => {
    return data.map((item) => [
      { key: 'customer', content: get(item, 'firstName', ''), type: 'first' },
      { key: 'name', content: get(item, 'lastName', '') },
      { key: 'sales', content: get(item, 'phoneNumber', '') },
      { key: 'type', content: get(item, ['company_id', 'name'], '') },
      {
        key: 'text',
        content: get(item, ['idMentor', 'email'], ''),
        type: 'latest'
      },
      {
        key: 'date',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              getInfoclient(item);
              window.scroll(0, 0);
            }}
          >
            <Visibility className={classes.icon} />
          </IconButton>
        )
      }
    ]);
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter
          financeFunc={(finance) => getclients(finance)}
          title="USUARIAS"
          filterChange={(value) => filetContent(value)}
        />
      </Grid>
      <Grid style={{ marginTop: '6vh' }}>
        {detailUser !== null && infoClient !== null && (
          <ClientDetail
            client={infoClient}
            closeDetail={() => setdetailUser(null)}
          />
        )}
        {getItem('filterFinance') === 'all' ? (
          <Table headers={headersOther} body={createSecondData(filterData)} />
        ) : (
          <Table headers={headers} body={createData(filterData)} />
        )}
      </Grid>
    </Grid>
  );
};

export { Clients };
