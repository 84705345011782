/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './HomeStyles';
import { CardBorder } from '../CardBorder/Index';
//import { Images } from '../../assets/Images';
import { HomeContext } from '../../Context/Home/HomeContext';
import { Filter } from '../Filter';
import Chart from 'react-apexcharts';
const humanFormat = require('human-format');
import { getItem } from '../../utils/persistentStorage';
import _ from 'lodash';

const Home = () => {
  const classes = useStyles();
  const {
    getAnalitycs,
    chartsHome,
    headerAnalityc,
    filterFinancial,
    chartDisbursed
  } = useContext(HomeContext);
  const [charNewClients, setcharNewClients] = useState(null);
  const [chartDistributed, setchartDistributed] = useState(null);
  const [chartEngament, setchartEngament] = useState(null);

  const optionsLinealChart = {
    chart: {
      height: 550,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    colors: ['#FC7B09', '#ED394B', '#EA285D'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    }
  };

  useEffect(() => {
    if (chartsHome !== null) {
      const seriesChart = _.get(chartsHome, 'newUser', []).map(
        (itemChart) => itemChart.Y
      );
      setcharNewClients({
        series: [
          {
            name: 'Usuarios',
            data: seriesChart
          }
        ],
        options: {
          ...optionsLinealChart,
          xaxis: {
            categories: _.get(chartsHome, 'newUser', []).map(
              (itemChart) => itemChart.X
            )
          }
        }
      });
      setchartEngament({
        series: [
          _.get(chartsHome, ['appEng', 'withoutLogin'], 0),
          _.get(chartsHome, ['appEng', 'loginWeek'], 0),
          _.get(chartsHome, ['appEng', 'loginMonth'], 0)
        ],
        options: {
          chart: {
            width: 380,
            type: 'pie'
          },
          colors: ['#FC7B09', '#ED394B', '#EA285D'],
          labels: [
            'Sin inicio de sesión',
            'Inicio de sesión semanal',
            'Inicio de sesión en el mes'
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]
        }
      });
    }
  }, [chartsHome]);

  useEffect(() => {
    if (getItem('filterFinance') === null) {
      chartsHome === null && getAnalitycs();
    }
  }, []);
  useEffect(() => {
    if (chartDisbursed !== null) {
      const seriesChart = _.get(chartDisbursed, 'result', []).map(
        (itemChart) => itemChart.total
      );

      setchartDistributed({
        series: [
          {
            name: 'Monto',
            data: seriesChart
          }
        ],
        options: {
          ...optionsLinealChart,
          xaxis: {
            categories: _.get(chartDisbursed, 'result', []).map(
              (itemChart) => itemChart.date
            )
          }
        }
      });
    }
  }, [chartDisbursed]);

  return (
    <Grid className={classes.rootContainer}>
      <Grid container spacing={2}>
        <Filter
          title="MÉTRICAS GENERALES"
          financeFunc={(finance, type) => filterFinancial(finance, type)}
        />
      </Grid>
      <CardBorder>
        <Grid container>
          <Grid item md={2} xs={6} sm={4} className={classes.containerSelector}>
            <Typography className={classes.titleSelector}>
              Nº DE CLIENTES
            </Typography>
            <Typography className={classes.subtitleSelector}>
              {headerAnalityc && humanFormat(headerAnalityc.totalClients)}
            </Typography>
          </Grid>
          <Grid item md={2} xs={6} sm={4} className={classes.containerSelector}>
            <Typography className={classes.titleSelector}>
              PRÉSTAMOS OTORGADOS
            </Typography>
            <Typography className={classes.subtitleSelector}>
              {headerAnalityc && humanFormat(headerAnalityc.totalLoansGranted)}
            </Typography>
          </Grid>
          <Grid item md={2} xs={6} sm={4} className={classes.containerSelector}>
            <Typography className={classes.titleSelector}>
              CAPITAL ACTIVO
            </Typography>
            <Typography className={classes.subtitleSelector}>
              {headerAnalityc && humanFormat(headerAnalityc.capitalActive)}
            </Typography>
          </Grid>
          <Grid item md={2} xs={6} sm={4} className={classes.containerSelector}>
            <Typography className={classes.titleSelector}>
              MONTO PRÉSTAMO PROMEDIO
            </Typography>
            <Typography className={classes.subtitleSelector}>
              {headerAnalityc &&
                humanFormat(
                  headerAnalityc.averageLoanAmount !== null
                    ? headerAnalityc.averageLoanAmount
                    : 0
                )}
            </Typography>
          </Grid>
          <Grid item md={2} xs={6} sm={4} className={classes.containerSelector}>
            <Typography className={classes.titleSelector}>
              TASA DE RETENCIÓN
            </Typography>
            <Typography className={classes.subtitleSelector}>
              {headerAnalityc && humanFormat(headerAnalityc.rateRetention)}%
            </Typography>
          </Grid>
          <Grid item md={2} xs={6} sm={4} className={classes.containerSelector}>
            <Typography className={classes.titleSelector}>
              PORTFOLIO EN RIESGO
            </Typography>
            <Typography className={classes.subtitleLast}>
              {headerAnalityc && humanFormat(headerAnalityc.portfolioRisk)}%
            </Typography>
          </Grid>
        </Grid>
      </CardBorder>
      <Grid container spacing={8}>
        <Grid item md={12} xs={12}>
          <CardBorder title="MONTO DESEMBOLSADO">
            {chartDistributed !== null && (
              <Chart
                options={chartDistributed.options}
                series={chartDistributed.series}
                type="line"
              />
            )}
          </CardBorder>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <CardBorder title="NUEVOS CLIENTES">
            {charNewClients !== null && (
              <Chart
                options={charNewClients.options}
                series={charNewClients.series}
                type="line"
              />
            )}
          </CardBorder>
        </Grid>
        <Grid item md={6} xs={12}>
          <CardBorder title="APP / BOT ENGAGEMENT">
            {chartEngament !== null && (
              <Chart
                options={chartEngament.options}
                series={chartEngament.series}
                type="pie"
              />
            )}
          </CardBorder>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { Home };
