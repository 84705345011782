/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  input: {},
  mainContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '50px'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '13px !important',
    margin: '6px !important',
    '& :hover': {
      color: theme.palette.primary.main
    },
    height: '74px',
    marginLeft: '25px ',
    width: '74px'
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  },
  label: { fontSize: '14px !important', fontWeight: '800!important' },
  select: {
    '& .MuiFilledInput-root ': {
      borderRadius: '50px',
      border: '0px'
    },
    '& .MuiFilledInput-root:before': {
      border: 'none !important'
    },
    '& .MuiFilledInput-root:after': {
      border: 'none !important'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '40px',
      color: theme.palette.primary.main
    },
    '& .MuiSelect-select': {
      fontSize: '22px !important',
      textAlign: 'start'
    }
  },
  buttonSend: {
    color: '#575756 !important',
    fontSize: '35px !important',
    fontWeight: '800 !important'
  },
  containerButton: {
    backgroundColor: 'white',
    borderRadius: '40px',
    marginTop: '42px'
  }
}));

export { useStyles };
