import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Typography,
  FilledInput
} from '@mui/material';
import { useStyles } from './InputStyles';

const Input = ({
  label,
  placeholder,
  type,
  value,
  onChange,
  background,
  helperText,
  inputProps,
  typeForm,
  ...morProps
}) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <FormControl
        size="small"
        variant="filled"
        fullWidth
        className={
          typeForm === 'loggin'
            ? classes.loggin
            : background
            ? classes.rootbg
            : helperText
            ? classes.error
            : classes.root
        }
      >
        {label && <InputLabel>{label}</InputLabel>}

        <FilledInput
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => onChange(e)}
          {...morProps}
          disableUnderline
          inputProps={inputProps}
          className={
            typeForm === 'loggin'
              ? classes.loggin
              : background
              ? classes.rootbg
              : helperText
              ? classes.error
              : classes.root
          }
        />
      </FormControl>
      {helperText && (
        <Typography className={classes.helperText}>{helperText}</Typography>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  background: PropTypes.bool,
  inputProps: PropTypes.object,
  typeForm: PropTypes.string
};

export { Input };
