/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    borderRadius: '50px',
    textTransform: 'uppercase',
    '& .MuiFilledInput-root ': {
      borderRadius: '50px',
      backgroundColor: 'white',
      border: '0px'
    },
    '& .MuiSelect-filled': {
      paddingRight: '40px !important'
    },
    '& .MuiFilledInput-root:before': {
      border: 'none !important'
    },
    '& .MuiFilledInput-root:after': {
      border: 'none !important'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '40px',
      color: theme.palette.primary.main
    },
    '& .MuiSelect-select': {
      fontSize: '18px !important',
      textAlign: 'start'
    },
    '& .MuiFilledInput-input': {
      paddingTop: '10px !important'
    }
  }
}));

export { useStyles };
