/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useStyles } from './AddNotificationsStyles';
import { Input } from '../../Input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

const AddNotifications = ({ notificationDetail, addNotification, close }) => {
  const classes = useStyles();
  const [ImageBase, setImageBase] = useState(null);
  const [imageUpdate, setimageUpdate] = useState(null);
  const [errorImage, seterrorImage] = useState(null);

  const selectImage = (event) => {
    if (event.target.files[0].name.match(/\.(jpeg|png)$/) !== null) {
      var reader = new FileReader();
      reader.onloadend = function () {
        setImageBase(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setimageUpdate(event.target.files[0]);
      seterrorImage(null);
    } else {
      seterrorImage('selecicona una imagen valida');
    }
  };
  const validationSchema = yup.object({
    body: yup
      .string('se requiere una descripcciòn con letras')
      .min(10, 'se requiere una descripcciòn con almenos 10 caracteres')
      .required('La descripcciòn es requerida'),
    title: yup
      .string('Se requiere un correo con letras')
      .required('El titulo es un campo obligatorio'),
    type: yup
      .string('Se requiere un correo con letras')
      .required('El orden es un campo obligatorio')
  });
  const formik = useFormik({
    initialValues: {
      title: notificationDetail ? notificationDetail.title : '',
      body: notificationDetail ? notificationDetail.body : '',
      type: notificationDetail ? notificationDetail.type : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      await addNotification({ ...values, file: imageUpdate });
      close();
    }
  });

  return (
    <Grid className={classes.rootContainer}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={classes.form}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '1vh' }}>
          <h1>
            {notificationDetail
              ? 'Editar Notificaciòn'
              : 'Agregar notificaciòn'}
          </h1>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Input
              placeholder="Titulo"
              value={formik.values.title}
              onChange={formik.handleChange}
              id="title"
              label="Titulo"
              type="text"
              inputProps={{
                minLength: 4,
                maxLength: 50,
                autoComplete: 'off'
              }}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>

          <Grid item md={6}>
            <Input
              placeholder="Tipo"
              value={formik.values.type}
              onChange={formik.handleChange}
              id="type"
              label="Tipo"
              type="number"
              inputProps={{
                minLength: 2,
                maxLength: 50,
                autoComplete: 'off'
              }}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            />
          </Grid>
          <Grid item md={12}>
            <Input
              placeholder="Cuerpo"
              value={formik.values.body}
              onChange={formik.handleChange}
              id="body"
              name="body"
              label="Cuerpo"
              type="text"
              inputProps={{
                minLength: 4,
                maxLength: 50,
                autoComplete: 'off'
              }}
              multiline
              rows={2}
              error={formik.touched.body && Boolean(formik.errors.body)}
              helperText={formik.touched.body && formik.errors.body}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            style={{
              marginTop: '20px',
              marginBottom: '25px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <input
              accept=".jpeg,.png"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={selectImage}
            />
            <label htmlFor="contained-button-file">
              {notificationDetail && notificationDetail.image ? (
                <>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: '20px',
                      cursor: 'pointer',
                      marginBottom: '25px'
                    }}
                    className={classes.label}
                  >
                    Cambiar imagen
                  </Typography>
                  <img
                    alt="Upload-icon"
                    style={{
                      height: '200px',
                      marginRight: '10px',
                      cursor: 'pointer',
                      width: '57px !important'
                    }}
                    src={notificationDetail.image}
                  />
                </>
              ) : (
                <>
                  <Typography>Selecciona una imagen</Typography>
                  {imageUpdate !== null && (
                    <img
                      src={ImageBase}
                      alt={formik.values.title}
                      style={{
                        height: '200px',
                        marginRight: '10px',
                        cursor: 'pointer',
                        width: '57px !important'
                      }}
                    />
                  )}
                </>
              )}
              {errorImage && (
                <Typography style={{ color: 'red' }}>{errorImage}</Typography>
              )}
            </label>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            md={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button type="submit">Guardar</Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

AddNotifications.propTypes = {
  notificationDetail: PropTypes.object,
  addNotification: PropTypes.func,
  close: PropTypes.func
};

export { AddNotifications };
