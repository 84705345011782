/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    height: 'auto',
    overflow: 'hidden',
    width: '100%'
  },
  containerInfo: {
    borderRadius: '48px',
    backgroundColor: 'white',
    paddingBottom: '10vh'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '13px !important',
    margin: '6px !important',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  contentButton: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  },
  name: {
    justifyContent: 'center',
    alignContent: 'center',
    padding: '0.1rem 2rem !important',
    fontSize: '22px !important'
  },
  imageContent: {
    display: 'flex',
    marginTop: '2rem !important'
  },
  barPayment: {
    borderRadius: '50px',
    padding: '1vh !important',
    margin: '1vh',
    color: 'white'
  }
}));

export { useStyles };
