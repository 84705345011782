import React from 'react';
import {
  Edit as EditIcon,
  Send,
  Add,
  Delete as DeleteIcon
} from '@mui/icons-material/';
import { Grid, IconButton } from '@mui/material';

const createData = (
  data,
  classes,
  setaddModal,
  seteditNotification,
  settypeSelect,
  setsendNotification,
  handleDelete
) => {
  const dataFormat = [
    [
      {
        key: 'customer',
        content: '',
        type: 'button'
      },
      { key: 'name', content: '', type: 'button' },
      {
        key: 'sales',
        type: 'button',
        content: ''
      },
      {
        key: 'distrit',
        content: '',
        type: 'button'
      },
      {
        key: 'type',
        type: 'button',
        content: ''
      },
      {
        key: 'text',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => setaddModal(true)}
          >
            <Add className={classes.icon} />
          </IconButton>
        )
      }
    ]
  ];
  data.map((itemClient) =>
    dataFormat.push([
      {
        key: 'customer',
        content: (
          <Grid>
            <strong>{itemClient.title}</strong>
            <br />
            {itemClient.type}
          </Grid>
        ),
        type: 'first'
      },
      { key: 'name', content: itemClient.body },
      {
        key: 'sales',
        type: 'latest',
        content: itemClient.image && (
          <img
            src={itemClient.image}
            alt={itemClient.title}
            className={classes.imagen}
          />
        )
      },
      {
        key: 'distrit',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={async () => {
              await seteditNotification(itemClient);
              setaddModal(true);
            }}
          >
            <EditIcon className={classes.icon} />
          </IconButton>
        ),
        type: 'button'
      },
      {
        key: 'type',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              settypeSelect(itemClient.type);
              setsendNotification(true);
            }}
          >
            <Send className={classes.icon} />
          </IconButton>
        )
      },
      {
        key: 'text',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => handleDelete(itemClient._id)}
          >
            <DeleteIcon className={classes.icon} />
          </IconButton>
        )
      }
    ])
  );
  return dataFormat;
};
const headers = [
  {
    key: 'customer',
    label: 'name'
  },
  { key: 'name', label: 'empres' },
  { key: 'sales', label: 'telefono' },
  { key: 'distrit', label: 'correo' },
  { key: 'type', label: 'ubicacion' },
  { key: 'text', label: 'if' }
];

export { createData, headers };
