/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, IconButton, Button } from '@mui/material';
import { useStyles } from './CoursesStyles';
import { Filter } from '../Filter';
import { CursesContext } from '../../Context/Curses';
import { getItem } from '../../utils/persistentStorage';
import { Modal } from '../Modal';
import { VideoList } from './VideoList';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add
} from '@mui/icons-material/';
import { DetailCurse } from './DetailCurse';
import { Table } from '../Table';
import { Select } from '../Select';
const Courses = () => {
  const classes = useStyles();
  const {
    getCusrses,
    listCurses,
    getVideosCourse,
    listVideo,
    changeStar,
    createVideo,
    deleteVideo,
    updateCurse,
    deleteCurse,
    createCurse
  } = useContext(CursesContext);
  const [confirmDelete, setconfirmDelete] = useState(false);

  const [filterData, setfilterData] = useState([]);
  const [curseId, setcurseId] = useState(null);
  const [updateModalCurse, setupdateModalCurse] = useState(null);
  const filterArray = ['title', 'course_id', 'visible'];
  const statusShow = [
    { label: 'Visible', value: true },
    { label: 'No Visible', value: false }
  ];

  useEffect(() => {
    getCusrses();
  }, []);

  const filetContent = async (value) => {
    setfilterData([]);

    if (listCurses !== null) {
      if (value === '' || value === null) {
        setfilterData([]);

        setfilterData([...listCurses]);
      } else {
        const arrayPushed = [];
        await listCurses.map((itemCurse) => {
          filterArray.map((keyObject) => {
            if (
              itemCurse[keyObject]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            ) {
              const index = arrayPushed.indexOf(itemCurse);
              if (index === -1) arrayPushed.push(itemCurse);
            }
          });
        });
        setfilterData([...arrayPushed]);
      }
    }
  };

  useEffect(() => {
    listCurses !== null && filetContent(getItem('searchValue'));
  }, [listCurses]);

  const getVideos = async (idVideo) => {
    await getVideosCourse(idVideo);
    setcurseId(idVideo);
  };

  const headersLit = [
    {
      key: 'customer',
      label: 'Customer'
    },
    { key: 'name', label: 'Name' },
    { key: 'sales', label: 'Sales Org.' },
    { key: 'distrit', label: 'Dsitr. Chanel.' },
    { key: 'type', label: 'Dsitr. Chanel.' }
  ];
  const createData = (data) => {
    const dataFormat = [
      [
        { key: 'customer', content: '', type: 'button' },
        {
          key: 'name',
          content: '',
          type: 'button'
        },
        {
          key: 'sales',
          content: '',
          type: 'button'
        },
        {
          key: 'distrit',
          content: '',
          type: 'button'
        },
        {
          key: 'type',
          type: 'button',
          content: (
            <IconButton
              className={classes.iconButton}
              onClick={() => setupdateModalCurse('create')}
            >
              <Add className={classes.icon} />
            </IconButton>
          )
        }
      ]
    ];
    data.map((itemClient) =>
      dataFormat.push([
        { key: 'customer', content: itemClient.description, type: 'first' },
        {
          key: 'name',
          content: (
            <Typography className={classes.contentTable}>
              Contiene&nbsp;
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => getVideos(itemClient._id)}
              >
                {itemClient.videoCount}&nbsp;vídeos
              </span>
            </Typography>
          )
        },
        {
          key: 'sales',
          content: (
            <Select
              value={itemClient.visible}
              options={statusShow}
              onChange={(e) =>
                updateCurse({
                  visible: e.target.value,
                  idCurse: itemClient._id
                })
              }
            />
          ),
          type: 'latest'
        },
        {
          key: 'distrit',
          content: (
            <IconButton
              className={classes.iconButton}
              onClick={() => setconfirmDelete(itemClient._id)}
            >
              <DeleteIcon className={classes.icon} />
            </IconButton>
          ),
          type: 'button'
        },
        {
          key: 'type',
          type: 'button',
          content: (
            <Grid item className={classes.contentButton}>
              <IconButton
                className={classes.iconButton}
                onClick={() => setupdateModalCurse(itemClient)}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            </Grid>
          )
        }
      ])
    );
    return dataFormat;
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter
          title="CURSOS Y VÍDEOS"
          filterChange={(value) => filetContent(value)}
        />
      </Grid>

      {listVideo === null ? (
        <Grid style={{ marginTop: '1vh' }}>
          {filterData !== null && (
            <Table headers={headersLit} body={createData(filterData)} />
          )}
        </Grid>
      ) : (
        <VideoList
          listVideo={listVideo}
          listCurses={listCurses}
          changeStatus={(values) => changeStar(values)}
          curseId={curseId}
          createCurse={(values) => createVideo(values)}
          deleteVideo={(idVideo) => deleteVideo(idVideo)}
        />
      )}

      <Modal
        modal={{
          show: confirmDelete,
          changeVisibility: () => setconfirmDelete(false),
          close: () => setconfirmDelete(false)
        }}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <Typography className={classes.titleModal}>Eliminar Curso</Typography>
          <Typography
            style={{
              fontSize: '20px'
            }}
          >
            Seguro que deseas eliminar este curso
          </Typography>
        </Grid>
        <Grid container>
          <Grid
            item
            md={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              onClick={() => setconfirmDelete(false)}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              color="secondary"
              onClick={async () => {
                await deleteCurse(confirmDelete);
                setconfirmDelete(false);
              }}
            >
              Eliminar
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        modal={{
          show: updateModalCurse,
          changeVisibility: () => setupdateModalCurse(null),
          close: () => setupdateModalCurse(null)
        }}
      >
        <DetailCurse
          curseDetail={updateModalCurse}
          updateCurse={(values) =>
            updateModalCurse === 'create'
              ? createCurse({ ...values, name: values.title, visible: false })
              : updateCurse(values)
          }
          closeDetail={() => setupdateModalCurse(null)}
        />
      </Modal>
    </Grid>
  );
};

export { Courses };
