import React, { Component } from 'react';
import { routes } from './Routes/Routes';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import LoaderState from './Context/Loader/LoaderState';
import { UsersAdminState } from './Context/UsersAdmin/UsersAdminState';
import { CursesState } from './Context/Curses';
import { FinanceState } from './Context/Finances';
import { HomeState } from './Context/Home';
import { CuotesPaymentState } from './Context/CuotesPayment';
import { ClientsState } from './Context/Clients';
import { FilterUsersState } from './Context/FilterUsers';
import { NotificationsState } from './Context/Notifications';
import { CreditState } from './Context/Credit';
import { CreditCalendarState } from './Context/CreditCalendar';
import { BehaviorState } from './Context/Behavior';
import { FinancesState } from './Context/Filter/';
import { Loader } from './Components/Loader/Loader';
import { LogginState } from './Context/Loggin/LogginState';
import { ModalContext } from './Components/ModalContext';
import ModalState from './Context/Modal/ModalState';

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            {routes.map((itemRoute) => (
              <Route
                key={itemRoute.path}
                path={itemRoute.path}
                exact={itemRoute.exact}
                render={(props) => (
                  <ThemeProvider theme={theme}>
                    <ModalState>
                      <LoaderState>
                        <LogginState>
                          <UsersAdminState>
                            <CursesState>
                              <FinanceState>
                                <HomeState>
                                  <CuotesPaymentState>
                                    <ClientsState>
                                      <FilterUsersState>
                                        <CreditState>
                                          <CreditCalendarState>
                                            <BehaviorState>
                                              <NotificationsState>
                                                <FinancesState>
                                                  <Loader />
                                                  <ModalContext />
                                                  <itemRoute.layaout
                                                    routes={routes}
                                                  >
                                                    <itemRoute.component
                                                      {...props}
                                                    />
                                                  </itemRoute.layaout>
                                                </FinancesState>
                                              </NotificationsState>
                                            </BehaviorState>
                                          </CreditCalendarState>
                                        </CreditState>
                                      </FilterUsersState>
                                    </ClientsState>
                                  </CuotesPaymentState>
                                </HomeState>
                              </FinanceState>
                            </CursesState>
                          </UsersAdminState>
                        </LogginState>
                      </LoaderState>
                    </ModalState>
                  </ThemeProvider>
                )}
              />
            ))}
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
