/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '50px !important',
    fontWeight: '800 !important',
    marginTop: '5vh !important'
  }
}));

export { useStyles };
