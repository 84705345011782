import { ACTIVITY_CHART } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIVITY_CHART:
      return {
        ...state,
        activityChart: payload
      };
    default:
      return state;
  }
};
