import React from 'react';
import { Typography, IconButton } from '@mui/material';
import { Delete, Add } from '@mui/icons-material/';
import { Select } from '../Select';

const headers = [
  {
    key: 'email',
    label: 'Correo'
  },
  { key: 'select', label: 'Select' },
  { key: 'changePassword', label: 'Select' },
  { key: 'delete', label: 'Select' }
];
const mapData = (
  data,
  listRoles,
  updateRole,
  session,
  classes,
  setupdatePassword,
  handleDelete,
  addUser
) => {
  const dataFormat = [
    [
      { key: 'email', content: '', type: 'button' },
      {
        key: 'select',
        content: '',
        type: 'button'
      },
      {
        key: 'changePassword',
        content: '',
        type: 'button'
      },
      {
        key: 'delete',
        type: 'button',
        content: (
          <IconButton
            size="large"
            className={classes.iconButton}
            onClick={() => addUser()}
          >
            <Add className={classes.addButton} />
          </IconButton>
        )
      }
    ]
  ];
  data.map((item) =>
    dataFormat.push([
      { key: 'email', content: item.email, type: 'first' },
      {
        key: 'select',
        content: (
          <Select
            value={
              listRoles.filter((itemRole) => itemRole._id === item.rol)[0].name
            }
            options={listRoles.map((item) => {
              return {
                label: item.name === 'mentor' ? 'MENTORA' : item.name,
                value: item.name
              };
            })}
            disabled={session.rol !== 'admin'}
            onChange={(e) => updateRole(e.target.value, item._id)}
          />
        )
      },
      {
        key: 'changePassword',
        content: (
          <Typography
            style={{
              textDecoration: 'underline',
              fontSize: '22px',
              cursor: 'pointer'
            }}
            onClick={() => setupdatePassword(item._id)}
          >
            Cambiar <br />
            contraseña
          </Typography>
        ),
        type: 'latest'
      },
      {
        key: 'delete',
        type: 'button',
        content: (
          <IconButton
            size="large"
            className={classes.iconButton}
            onClick={() => handleDelete(item._id)}
          >
            <Delete className={classes.addButton} />
          </IconButton>
        )
      }
    ])
  );
  return dataFormat;
};

export { headers, mapData };
