/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { NotificationsContext } from './NotificationsContext';
import LogginReducer from './NotificationsReducer';
import { useApi } from '../../Hooks';
import { GET_NOTIFICATIONS } from '../Types';

const NotificationsState = (props) => {
  const initialState = {
    listNotification: null
  };

  const [getAllNotifications] = useApi({
    endpoint: 'api/v1/dashboard/notification',
    method: 'get'
  });

  const [addNewNotification] = useApi({
    endpoint: 'api/v1/dashboard/notification',
    method: 'post'
  });

  const [deleteNewNotification] = useApi({
    endpoint: 'api/v1/dashboard/notification',
    method: 'delete'
  });

  const [updateNofitications] = useApi({
    endpoint: 'api/v1/dashboard/notification',
    method: 'put'
  });

  const [sendUserNotification] = useApi({
    endpoint: 'api/v1/dashboard/notification/sendAll',
    method: 'post'
  });
  const [state, dispatch] = useReducer(LogginReducer, initialState);

  const getNotifications = async () => {
    const response = await getAllNotifications({
      body: {},
      message: false,
      url: process.env.REACT_APP_BACKEND_URL
    });

    dispatch({ type: GET_NOTIFICATIONS, payload: response.data });
  };

  const updateNotification = async (values) => {
    const formData = new FormData();

    Object.keys(values).map(
      (itemKey) =>
        values[itemKey] !== null && formData.append(itemKey, values[itemKey])
    );

    const response = await updateNofitications({
      body: formData,
      url: process.env.REACT_APP_BACKEND_URL,
      message: true,
      urlParams: `/${values.id}`
    });
    setTimeout(async () => {
      if (response.message.includes('Actualizada')) getNotifications();
    }, 200);
  };
  const addNotification = async (values) => {
    const formData = new FormData();

    Object.keys(values).map(
      (itemKey) =>
        values[itemKey] !== null && formData.append(itemKey, values[itemKey])
    );

    const response = await addNewNotification({
      body: formData,
      url: process.env.REACT_APP_BACKEND_URL,
      message: true
    });
    setTimeout(async () => {
      if (response.message.includes('correctamente')) getNotifications();
    }, 200);
  };
  const deleteNotification = async (idNotification) => {
    const response = await deleteNewNotification({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      message: true,
      urlParams: `/${idNotification}`
    });

    setTimeout(async () => {
      if (response.message.includes('eliminada')) getNotifications();
    }, 200);
  };
  const sendNotification = async (idFinance, typeSelect) => {
    await sendUserNotification({
      body: { companyID: idFinance },
      url: process.env.REACT_APP_BACKEND_URL,
      message: true,
      urlParams: `/${typeSelect}`
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        listNotification: state.listNotification,
        getNotifications,
        updateNotification,
        deleteNotification,
        addNotification,
        sendAllNotification: sendNotification
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsState };
