import { Login } from '../Components/Login';
import { Home } from '../Components/Home';
import { Credit } from '../Components/Credit';
import { Behavior } from '../Components/Behavior';
import { Courses } from '../Components/Courses';
import { UsersAdmin } from '../Components/UsersAdmin';
import { CuotesPayment } from '../Components/CuotesPayment';
import { Clients } from '../Components/Clients';
import { Notifications } from '../Components/Notifications';
import { CreditCalendar } from '../Components/CreditCalendar';
import { BasicLayout, FullScreneLayout } from '../Layout';
import { Images } from '../assets/Images';
import { FilterUsers } from '../Components/FilterUsers';
import { Finances } from '../Components/Finances';

const routes = [
  {
    component: Login,
    path: '/',
    exact: true,
    layaout: FullScreneLayout,
    menu: false
  },
  {
    component: Home,
    path: '/Home',
    exact: true,
    layaout: BasicLayout,
    icon: Images.HomeIcon,
    menu: true
  },
  {
    component: Credit,
    path: '/Credit',
    exact: true,
    layaout: BasicLayout,
    icon: Images.DataBaseIcon,
    menu: true
  },
  {
    component: Behavior,
    path: '/Analitycs',
    exact: true,
    layaout: BasicLayout,
    icon: Images.MovileIcon,
    menu: true
  },
  {
    component: Clients,
    path: '/Users',
    exact: true,
    layaout: BasicLayout,
    icon: Images.UserIcon,
    menu: true
  },
  {
    component: FilterUsers,
    path: '/Users-Filter',
    exact: true,
    layaout: BasicLayout,
    icon: Images.FilterIcon,
    menu: true
  },
  {
    component: Courses,
    path: '/Videos',
    exact: true,
    layaout: BasicLayout,
    icon: Images.MovieIcon,
    menu: true
  },

  {
    component: UsersAdmin,
    path: '/Users-Ada',
    exact: true,
    layaout: BasicLayout,
    icon: Images.MovieIcon,
    menu: false
  },

  {
    component: CuotesPayment,
    path: '/Cuotes-Payment',
    exact: true,
    layaout: BasicLayout,
    icon: Images.MovieIcon,
    menu: false
  },
  {
    component: Notifications,
    path: '/Notifications',
    exact: true,
    layaout: BasicLayout,
    icon: Images.NotificationIcon,
    menu: true
  },
  {
    component: CreditCalendar,
    path: '/Credit-Calendar',
    exact: true,
    layaout: BasicLayout,
    icon: Images.MovieIcon,
    menu: false
  },
  {
    component: Finances,
    path: '/Finances',
    exact: true,
    layaout: BasicLayout,
    icon: Images.NotificationIcon,
    menu: false
  }
];

export { routes };
