import { CHART_PAYMENT, TIME_CHART } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case CHART_PAYMENT:
      return {
        ...state,
        listChart: payload
      };
    case TIME_CHART:
      return {
        ...state,
        timePaimentChart: payload
      };

    default:
      return state;
  }
};
