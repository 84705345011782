const headers = [
  {
    key: 'customer',
    label: 'name'
  },
  { key: 'name', label: 'empres' },
  { key: 'sales', label: 'telefono' },
  { key: 'distrit', label: 'correo' },
  { key: 'type', label: 'ubicacion' },
  { key: 'text', label: 'if' },
  { key: 'date', label: 'viw' }
];

const headersOther = [
  {
    key: 'customer',
    label: 'name'
  },
  { key: 'name', label: 'empres' },
  { key: 'sales', label: 'telefono' },
  { key: 'type', label: 'ubicacion' },
  { key: 'text', label: 'if' },
  { key: 'date', label: 'viw' }
];

export { headers, headersOther };
