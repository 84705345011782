import { CHART_CREDIT } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case CHART_CREDIT:
      return {
        ...state,
        graphicOdds: payload
      };

    default:
      return state;
  }
};
