/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { useStyles } from './FinancesStyles';
import { FinancesContext } from '../../Context/Finances';
import { getItem } from '../../utils/persistentStorage';
import { Modal } from '../Modal';
import { DetailFinance } from './DetailFinance';
import { Add, Edit as EditIcon } from '@mui/icons-material/';
import { Table } from '../Table';
import { Select } from '../Select';

const Finances = () => {
  const classes = useStyles();
  const { getallFinances, listFinances, updateFinance, createFinance } =
    useContext(FinancesContext);
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [filterData, setfilterData] = useState([]);
  const [updateModalFinance, setupdateModalFinance] = useState(null);
  const filterArray = ['title', 'visible'];
  const session = getItem('session');

  useEffect(() => {
    getallFinances();
  }, []);
  const optionActive = [
    { label: 'Activo', value: true },
    { label: 'Inactivo ', value: false }
  ];

  const filetContent = async (value) => {
    setfilterData([]);

    if (listFinances !== null) {
      if (value === '' || value === null) {
        setfilterData([]);

        setfilterData([...listFinances]);
      } else {
        const arrayPushed = [];
        await listFinances.map((itemCurse) => {
          filterArray.map((keyObject) => {
            if (
              itemCurse[keyObject]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            ) {
              const index = arrayPushed.indexOf(itemCurse);
              if (index === -1) arrayPushed.push(itemCurse);
            }
          });
        });
        setfilterData([...arrayPushed]);
      }
    }
  };

  useEffect(() => {
    listFinances !== null && filetContent(getItem('searchValue'));
  }, [listFinances]);

  const headers = [
    {
      key: 'customer',
      label: 'Customer'
    },
    {
      key: 'select',
      label: 'Customer'
    },
    {
      key: 'edit',
      label: 'Customer'
    }
  ];
  const createData = (data) => {
    const dataFormat = [
      [
        { key: 'customer', content: '', type: 'button' },
        {
          key: 'select',
          type: 'button',
          content: ''
        },
        {
          key: 'edit',
          type: 'button',
          content: (
            <IconButton
              className={classes.iconButton}
              onClick={() => setupdateModalFinance('create')}
            >
              <Add className={classes.icon} />
            </IconButton>
          )
        }
      ]
    ];
    data.map((item) =>
      dataFormat.push([
        { key: 'customer', content: item.name, type: 'first' },
        {
          key: 'select',
          type: 'latest',
          content: (
            <Select
              value={item.Active}
              onChange={(e) =>
                updateFinance({
                  idfinance: item._id,
                  type: 'delete',
                  status: e.target.value
                })
              }
              options={optionActive}
              disabled={session.rol !== 'admin'}
            />
          )
        },
        {
          key: 'edit',
          type: 'button',
          content: (
            <IconButton
              className={classes.iconButton}
              onClick={() => setupdateModalFinance(item)}
            >
              <EditIcon className={classes.icon} />
            </IconButton>
          )
        }
      ])
    );
    return dataFormat;
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid style={{ marginTop: '1vh' }}>
        {filterData !== null && (
          <Table body={createData(filterData)} headers={headers} />
        )}
      </Grid>

      <Modal
        modal={{
          show: confirmDelete,
          changeVisibility: () => setconfirmDelete(false),
          close: () => setconfirmDelete(false)
        }}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <Typography className={classes.titleModal}>
            Eliminar Financiera
          </Typography>
          <Typography
            style={{
              fontSize: '20px'
            }}
          >
            Si eliminas esta financiera no la podras recuperar
          </Typography>
        </Grid>
      </Modal>
      <Modal
        modal={{
          show: updateModalFinance,
          changeVisibility: () => setupdateModalFinance(null),
          close: () => setupdateModalFinance(null)
        }}
      >
        <DetailFinance
          financeDetail={updateModalFinance}
          updateFinance={(values) =>
            updateModalFinance === 'create'
              ? createFinance({ ...values, name: values.name, visible: false })
              : updateFinance(values)
          }
          closeDetail={() => setupdateModalFinance(null)}
        />
      </Modal>
    </Grid>
  );
};

export { Finances };
