/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Avatar, IconButton } from '@mui/material';
import { useStyles } from './ClientDetailStyles';
import { CardBorder } from '../../CardBorder/Index';
import { VisibilityOff } from '@mui/icons-material/';
import { format } from 'date-fns';
import { get } from 'lodash';

const ClientDetail = ({ client, closeDetail }) => {
  const classes = useStyles();
  const { infoPersonal } = client;
  const colorsState = {
    carencia: '#E5E5E5',
    Impagado: 'red',
    Pagado: '#148400',
    Futura: '#343434'
  };

  console.log(client);
  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Grid item md={11} className={classes.containerInfo}>
          <Grid container spacing={3} direction="row" justifyContent="center">
            <Grid item md={11} className={classes.imageContent}>
              <Avatar src={infoPersonal.photo} alt={infoPersonal.lastName} />
              <Typography className={classes.name}>
                {infoPersonal.firstName} {infoPersonal.lastName}
              </Typography>
            </Grid>
            <Grid item md={5}>
              <CardBorder title="INFORMACIÓN PERSONAL" border>
                <Typography>
                  Nombre: {infoPersonal.firstName} {infoPersonal.lastName}
                </Typography>
                <Typography>CURP: {infoPersonal.curp}</Typography>
                <Typography>Dirección: {infoPersonal.homeAddress}</Typography>
              </CardBorder>
            </Grid>
            <Grid item md={5}>
              <CardBorder title="INFORMACIÓN DEL CRÉDITO" border>
                {client.infoPayments.map((itemPyment) => (
                  <Grid key={itemPyment.creditStart}>
                    <Typography>
                      Comienzo del crédito:
                      {format(new Date(itemPyment.creditStart), 'dd/MM/yyyy')}
                    </Typography>
                    <Typography>
                      Final crédito:{' '}
                      {format(
                        new Date(itemPyment.creditDeadline),
                        'dd/MM/yyyy'
                      )}
                    </Typography>
                    <Typography>
                      Monto total del crédito: {itemPyment.creditAmount}
                    </Typography>
                    {itemPyment.creditState && <Typography>Estatus: {itemPyment.creditState}</Typography>}
                    {itemPyment.cycle  && itemPyment.cycle > 1 &&  <Typography>Estatus: RENOVACIÓN </Typography> }
                    {itemPyment.cycle  && itemPyment.cycle < 2 &&  <Typography>Estatus: NUEVO </Typography> }
                    <Typography>Frecuencia de pago: {itemPyment.creditPaymentsFrequency}</Typography>
                    
                    

                
                  </Grid>
                ))}
              </CardBorder>
            </Grid>
            <Grid item md={10}>
              {client.infoPayments.map((itemPyment) => (
                <CardBorder
                  title="CALENDARIO DE PAGOS"
                  border
                  key={itemPyment.creditStart}
                  padding={false}
                >
                  {itemPyment.payments.map((itemPayment) => (
                    <Grid
                      key={itemPayment}
                      style={{
                        backgroundColor: colorsState[itemPayment.pagoState]
                      }}
                      className={classes.barPayment}
                      container
                    >
                      <Grid item md={2}>
                        <strong>{itemPayment.pagoState}</strong>
                      </Grid>
                      <Grid item md={2}>
                        {get(itemPayment, 'pagoFee', '')}
                      </Grid>
                      <Grid item md={5}>
                        {itemPayment.pagoPaid}
                      </Grid>
                      <Grid item md={2}>
                        {format(
                          new Date(itemPayment.pagoDeadline),
                          'dd/MM/yyyy'
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </CardBorder>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1}>
          <IconButton
            className={classes.iconButton}
            onClick={() => closeDetail()}
          >
            <VisibilityOff className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

ClientDetail.propTypes = {
  client: PropTypes.object,
  closeDetail: PropTypes.func
};

export { ClientDetail };
