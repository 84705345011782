/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import { useStyles } from './SelectStyles';

const Select = ({ options, onChange, value, disabled, label, id, name }) => {
  const classes = useStyles();

  return (
    <TextField
      select
      onChange={(e) => onChange(e)}
      fullWidth
      className={classes.select}
      variant="filled"
      value={value}
      label={label}
      disabled={disabled}
      id={id}
      name={name}
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
Select.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string
};

export { Select };
