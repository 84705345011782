/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useStyles } from './NotificationsStyles';
import { NotificationsContext } from '../../Context/Notifications';
import { Filter } from '../Filter';
import { Modal } from '../Modal';
import { AddNotifications } from './AddNotification';
import { getItem } from '../../utils/persistentStorage';
import { Table } from '../Table';
import _ from 'lodash';
import { Select } from '../Select';
import { createData, headers } from './utils';

const Notifications = () => {
  const classes = useStyles();
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [idDelete, setidDelete] = useState(false);
  const [editNotification, seteditNotification] = useState(null);
  const [sendNotification, setsendNotification] = useState(null);
  const [financeSelect, setfinanceSelect] = useState(null);
  const [typeSelect, settypeSelect] = useState(null);
  const {
    listNotification,
    getNotifications,
    addNotification,
    deleteNotification,
    updateNotification,
    sendAllNotification
  } = useContext(NotificationsContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const handleDelete = (id) => {
    setconfirmDelete(true);
    setidDelete(id);
  };

  const finances = getItem('finances') !== null ? getItem('finances') : [];

  const enterprises = finances.map(
    (itemFinance) => itemFinance.mobileApp && itemFinance
  );
  const confirmNotification = async () => {
    let idFinance = '';
    _.get(finances, [], []).map((item) => {
      if (item.name === financeSelect) idFinance = item._id;
    });
    await sendAllNotification(idFinance, typeSelect);
    setfinanceSelect(null);
    settypeSelect(null);
    setsendNotification(false);
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter title="NOTIFICACIONES" />
      </Grid>
      {listNotification !== null && (
        <Table
          headers={headers}
          body={createData(
            listNotification,
            classes,
            setaddModal,
            seteditNotification,
            settypeSelect,
            setsendNotification,
            handleDelete
          )}
        />
      )}
      <Modal
        modal={{
          show: confirmDelete,
          changeVisibility: () => setconfirmDelete(false),
          close: () => setconfirmDelete(false)
        }}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <h1>Eliminar notificaciòn</h1>
          <Typography
            style={{
              fontSize: '20px'
            }}
          >
            ¿Seguro que deseas eliminar esta notificaciòn?
          </Typography>
        </Grid>
        <Grid container>
          <Grid item md={6} className={classes.centerContent}>
            <Button
              onClick={async () => {
                await deleteNotification(idDelete);
                setconfirmDelete(false);
                idDelete(false);
              }}
            >
              SI, Eliminar
            </Button>
          </Grid>
          <Grid item md={6} className={classes.centerContent}>
            <Button
              onClick={() => setconfirmDelete(false)}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        modal={{
          show: addModal,
          changeVisibility: () => {
            setaddModal(false);
            seteditNotification(null);
          },
          close: () => {
            setaddModal(false);
            seteditNotification(null);
          }
        }}
      >
        <AddNotifications
          addNotification={(values) => {
            editNotification === null
              ? addNotification(values)
              : updateNotification({ ...values, id: editNotification._id });
          }}
          close={() => {
            setaddModal(false);
            seteditNotification(null);
          }}
          notificationDetail={editNotification}
        />
      </Modal>
      <Modal
        modal={{
          show: sendNotification,
          changeVisibility: () => setsendNotification(false),
          close: () => setsendNotification(false)
        }}
      >
        <Grid container>
          <Grid item md={12}>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: 800
              }}
            >
              Enviar notificaciòn
            </Typography>
            <Typography className={classes.label}>
              Selecciona la financiera
            </Typography>
            <Select
              options={_.get(enterprises, [], []).map((option) => {
                return {
                  value: option.value ? option.value : option._id,
                  label: option.label ? option.label : option.name
                };
              })}
              onChange={(e) => setfinanceSelect(e.target.value)}
              value={financeSelect}
            />
          </Grid>

          <Grid item md={12} className={classes.centerButton}>
            <Button
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginRight: '2vh'
              }}
              disabled={financeSelect === null}
              onClick={() => confirmNotification()}
            >
              Enviar
            </Button>
            <Button
              style={{
                color: 'black'
              }}
              onClick={() => setsendNotification(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export { Notifications };
