/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden !important',
    backgroundRepeat: 'no-repeat',
    padding: '4vh',
    [theme.breakpoints.down('md')]: {
      padding: '1vh !important'
    }
  },
  titleMetrics: {
    color: theme.palette.primary.main,
    fontSize: '20px !important'
  },
  containerSelector: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: '1vh !important'
    }
  },
  titleSelector: {
    textAlign: 'center',
    fontSize: '13px  !important',
    fontWeight: '800 !important',
    whiteSpace: 'nowrap'
  },
  subtitleSelector: {
    fontSize: '50px !important',
    fontWeight: '800 !important',
    borderRight: '1px solid #FC7B09',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px !important'
    }
  },
  subtitleLast: {
    fontSize: '50px !important',
    fontWeight: '800 !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important'
    }
  },

  select: {
    borderRadius: '50px',
    '& .MuiFilledInput-root ': {
      borderRadius: '50px',
      backgroundColor: 'white',
      border: '0px'
    },
    '& .MuiFilledInput-root:before': {
      border: 'none !important'
    },
    '& .MuiFilledInput-root:after': {
      border: 'none !important'
    }
  }
}));

export { useStyles };
