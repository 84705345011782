import { LIST_USERS, GET_ROLES } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case LIST_USERS:
      return {
        ...state,
        listUsers: payload
      };
    case GET_ROLES:
      return {
        ...state,
        listRoles: payload
      };
    default:
      return state;
  }
};
