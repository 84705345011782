import React from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './CreditStyles';
import { CardBorder } from '../CardBorder/Index';
import { Filter } from '../Filter';

const Credit = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter title="CRÉDITO" />
      </Grid>
      <Grid container spacing={8}>
        <Grid item md={3} xs={12}>
          <CardBorder title="CALENDARIO" link="/Credit-Calendar"></CardBorder>
        </Grid>
        <Grid item md={9} xs={12}>
          <CardBorder
            title="PAGO DE CUOTAS"
            link="/Cuotes-Payment"
          ></CardBorder>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { Credit };
