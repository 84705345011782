/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  contentTable: {
    fontSize: '18px !important'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '10px !important',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  contentButton: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '20px !important'
  },
  titleModal: {
    fontSize: '30px !important',
    fontWeight: '800 !important'
  }
}));

export { useStyles };
