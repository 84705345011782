/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './FilterStyles';
import { setItem, getItem } from '../../utils/persistentStorage';
import { FinancesContext } from '../../Context/Filter';
import { Select } from '../Select';
import { Input } from '../Input';
const Filter = ({ title, financeFunc, filterChange }) => {
  const classes = useStyles();
  const daten = new Date();
  const dayToday = `${daten.getFullYear()}-${
    daten.getMonth() <= 8 ? `0${daten.getMonth() + 1}` : daten.getMonth() + 1
  }-${daten.getDate() < 10 ? `0${daten.getDate()}` : daten.getDate()}`;

  const { getFinaces } = useContext(FinancesContext);
  const [financieraSelected, setfinancieraSelected] = useState(
    getItem('filterFinance') === null ? 'all' : getItem('filterFinance')
  );

  useEffect(() => {
    getFinaces();
  }, []);

  const enterprises = getItem('finances') === null ? [] : getItem('finances');

  useEffect(() => {
    executeFilter();
    inputFilter();
  }, []);

  const executeFilter = (type) => {
    console.log('anaknaknak');
    financeFunc(
      getItem('filterFinance') !== null ? getItem('filterFinance') : 'all',
      type
    );
  };

  const inputFilter = () => {
    filterChange(getItem('searchValue') !== null ? getItem('searchValue') : '');
  };

  const generateOptions = (options) => {
    const optionsFormat = [{ label: 'Todas', value: 'all' }];
    options.map((item) =>
      optionsFormat.push({
        label: item.label ? item.label : item.name,
        value: item.value ? item.value : item.name
      })
    );
    return optionsFormat;
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <Typography className={classes.titleMetrics}>
          <strong>{title}</strong> - ADA Impacto
        </Typography>
      </Grid>
      <Grid item md={2} xs={6}>
        <Input
          placeholder="Busca por prestataria, financiera…"
          onChange={(e) => {
            setItem('searchValue', e.target.value);
            filterChange(e.target.value);
          }}
          value={getItem('searchValue') !== null ? getItem('searchValue') : ''}
          background
        />
      </Grid>
      <Grid item md={2} xs={6}>
        {enterprises !== null && (
          <Select
            value={financieraSelected}
            onChange={(e) => {
              setfinancieraSelected(e.target.value);
              setItem('filterFinance', e.target.value);
              financeFunc(e.target.value, 'filterFinance');
            }}
            options={generateOptions(enterprises)}
          />
        )}
      </Grid>
      <Grid item md={2} xs={6}>
        <Input
          onChange={(e) => {
            setItem('startDate', e.target.value);
            executeFilter('filterFinance');
          }}
          label="Fecha de inicio"
          value={
            getItem('startDate') !== null ? getItem('startDate') : '2021-09-27'
          }
          type="date"
          background
        />
      </Grid>
      <Grid item md={2} xs={6}>
        <Input
          onChange={(e) => {
            setItem('endDate', e.target.value);
            executeFilter('filterFinance');
          }}
          label="Fecha de fin"
          value={getItem('endDate') !== null ? getItem('endDate') : dayToday}
          type="date"
          background
          minDate="2022-03-22"
        />
      </Grid>
    </Grid>
  );
};

Filter.propTypes = {
  title: PropTypes.string,
  financeFunc: PropTypes.func,
  filterChange: PropTypes.func
};

Filter.defaultProps = {
  title: '',
  financeFunc: () => {
    console.log();
  },
  filterChange: () => {
    console.log();
  }
};
export { Filter };
