import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './CardBorderStyles';
import PropTypes from 'prop-types';

const CardBorder = ({ title, children, link, border, padding = true }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        padding: padding ? '2rem 2rem' : '1.5rem',
        border: border ? '1px solid' : ''
      }}
      className={classes.container}
    >
      <Grid md={12} xs={12} style={{ display: 'flex', marginBottom: '1vh' }}>
        {link ? (
          <a href={link} className={classes.link}>
            <Typography className={classes.titleLink}>{title}</Typography>
          </a>
        ) : (
          <Typography className={classes.titleLink}>{title}</Typography>
        )}
      </Grid>
      <Grid md={12} xs={12} sm={12}>
        {children}
      </Grid>
    </Grid>
  );
};
CardBorder.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  link: PropTypes.string,
  border: PropTypes.bool,
  padding: PropTypes.bool
};
export { CardBorder };
