export const CHANGE_LOADER = 'CHANGE_LOADER';
export const LOGGIN = 'LOGGIN';
export const CHANGE_CONTENT_MODAL = 'CHANGE_CONTENT_MODAL';
export const CHANGE_SHOW_MODAL = 'CHANGE_SHOW_MODAL';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const LIST_USERS = 'LIST_USERS';
export const LIST_CLIENTS = 'LIST_CLIENTS';
export const LIST_VIDEOS = 'LIST_VIDEOS';
export const LIST_CURSES = 'LIST_CURSES';
export const CHART_PAYMENT = 'CHART_PAYMENT';
export const CHARTS_HOME = 'CHARTS_HOME';
export const HEADER_ANALYTICS_HOME = 'HEADER_ANALYTICS_HOME';
export const CHART_CREDIT = 'CHART_CREDIT';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const WEEKLY_CHART = 'WEEKLY_CHART';
export const ACTIVITY_CHART = 'ACTIVITY_CHART';
export const TIME_CHART = 'TIME_CHART';
export const CLIENT_DETAIL = 'CLIENT_DETAIL';
export const CHART_DISBURSED = 'CHART_DISBURSED';
export const GET_FINANCES = 'GET_FINANCES';
export const GET_ROLES = 'GET_ROLES';
