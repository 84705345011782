/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  titleMetrics: {
    color: theme.palette.primary.main,
    fontSize: '22px !important'
  },
  containerSelector: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  contentTable: {
    fontSize: '18px !important'
  },
  lastItem: {
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '48px',
    margin: '8px 5px!important',

    padding: '13px 5px'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '13px !important',
    margin: '6px !important',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  contentButton: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  },
  titleModal: {
    fontSize: '30px !important',
    fontWeight: '800 !important'
  },
  select: {
    borderRadius: '50px',
    '& .MuiFilledInput-root ': {
      borderRadius: '50px',
      backgroundColor: 'white',
      border: '0px'
    },
    '& .MuiFilledInput-root:before': {
      border: 'none !important'
    },
    '& .MuiFilledInput-root:after': {
      border: 'none !important'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '40px',
      color: theme.palette.primary.main
    },
    '& .MuiSelect-select': {
      fontSize: '22px !important',
      textAlign: 'start'
    }
  },
  mayus: {
    textTransform: 'uppercase !important'
  }
}));

export { useStyles };
