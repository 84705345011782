import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { useStyles, openedMixin, closedMixin } from './MenuStyles';
import { Grid, Avatar, ListItem, IconButton } from '@mui/material';
import { Images } from '../../assets/Images';
import { deleteItem } from '../../utils/persistentStorage';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useLocation, useHistory } from 'react-router-dom';

const drawerWidth = 143;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

function Menu({ content, routes }) {
  const classes = useStyles();
  let location = useLocation();
  let history = useHistory();
  const changeRoute = (path) => {
    history.push(path);
  };
  const closeSesison = () => {
    deleteItem('filterFinance');
    deleteItem('session');
    deleteItem('startDate');
    deleteItem('endDate');
    deleteItem('finances');
    deleteItem('graphics-credit-amount');
    deleteItem('graphics-home');
    history.push('/');
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={false} className={classes.drawer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ minHeight: '100%' }}
        >
          <Grid
            item
            className={classes.containerButton}
            onClick={() => changeRoute('/Users-Ada')}
          >
            <Avatar className={classes.avatar}>
              <img src={Images.LogoAda} />
            </Avatar>
          </Grid>
          <Grid item xs={12}>
            {routes.map(
              (text) =>
                text.menu && (
                  <ListItem
                    key={text.path}
                    sx={{ display: 'block' }}
                    onClick={() => changeRoute(text.path)}
                    className={
                      location.pathname === text.path
                        ? classes.itemActive
                        : classes.itemDisabled
                    }
                  >
                    <img
                      src={text.icon}
                      alt={text.path}
                      className={
                        location.pathname === text.path
                          ? classes.iconActive
                          : classes.iconDisabled
                      }
                    />
                  </ListItem>
                )
            )}
          </Grid>
          <Grid item className={classes.centerContent}>
            <IconButton
              style={{ backgroundColor: '#EA285D' }}
              onClick={() => closeSesison()}
            >
              <PowerSettingsNewIcon className={classes.iconLogout} />
            </IconButton>
          </Grid>
        </Grid>
      </Drawer>
      <Box className={classes.containerContent}>{content}</Box>
    </Box>
  );
}
Menu.propTypes = {
  content: PropTypes.string,
  routes: PropTypes.object
};

export { Menu };
