/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useStyles } from './FilterUsersStyles';
import { FilterUsersContext } from '../../Context/FilterUsers';
import { Filter } from '../Filter';
import { getItem } from '../../utils/persistentStorage';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  optionsMora,
  paymentPeriod,
  optionsCredit,
  headers,
  createData
} from './constans';
import { Select } from '../Select';
import { Table } from '../Table';

const FilterUsers = () => {
  const classes = useStyles();
  const { listclients, getMoraClients } = useContext(FilterUsersContext);
  const [filterData, setfilterData] = useState([]);
  const [moraFilter, setmoraFilter] = useState(null);
  const [periodoFilter, setperiodoFilter] = useState(null);
  const [showTableExport, setshowTableExport] = useState(null);
  const [financeFilter, setfinanceFilter] = useState(getItem('filterFinance'));
  const filterArray = ['firstName', 'phoneNumber', 'community'];

  const filetContent = async (value) => {
    setfilterData([]);

    if (listclients !== null) {
      if (value === '' || value === null) {
        setfilterData([]);

        setfilterData([...listclients]);
      } else {
        const arrayPushed = [];
        await listclients.map((itemCurse) => {
          filterArray.map((keyObject) => {
            if (itemCurse[keyObject] !== undefined) {
              if (
                itemCurse[keyObject]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                const index = arrayPushed.indexOf(itemCurse);
                if (index === -1) arrayPushed.push(itemCurse);
              }
            }
          });
        });
        setfilterData([...arrayPushed]);
      }
    }
  };

  useEffect(() => {
    listclients !== null && filetContent(getItem('searchValue'));
  }, [listclients]);

  const generateTable = () => {
    setshowTableExport(true);
    setTimeout(() => {
      setshowTableExport(false);
    }, 100);
  };

  const getSome = (values) => {
    getMoraClients({ ...values, finance: financeFilter });
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter
          financeFunc={(finance) => setfinanceFilter(finance)}
          title="USUARIAS"
          filterChange={(value) => filetContent(value)}
        />
      </Grid>

      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '3vh' }}
      >
        <Grid item md={3} xs={12}>
          <Select
            onChange={async (e) => {
              await setmoraFilter(e.target.value);
              getSome({ mora: e.target.value, period: periodoFilter });
            }}
            value={moraFilter}
            options={optionsMora}
            label="Mora"
            disabled={financeFilter === null || financeFilter === 'all'}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Select
            onChange={async (e) => {
              await setperiodoFilter(e.target.value);
              getSome({ mora: moraFilter, period: e.target.value });
            }}
            value={periodoFilter}
            options={paymentPeriod}
            label="Periodo"
            disabled={financeFilter === null || financeFilter === 'all'}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Select
            onChange={(e) => console.log(e)}
            value={periodoFilter}
            options={optionsCredit}
            label="Tipo crédito"
            disabled={
              financeFilter === null ||
              financeFilter === 'all' ||
              moraFilter !== null
            }
          />
        </Grid>
        {filterData.length !== 0 && (
          <Grid item md={1} xs={12}>
            <Button
              onClick={() => {
                setperiodoFilter(null);
                setmoraFilter(null);
                setfilterData([]);
              }}
              variant="contained"
            >
              Limpiar
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid style={{ marginTop: '6vh' }}>
        {filterData.length !== 0 && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={10}>
              <Table headers={headers} body={createData(filterData)} />
            </Grid>
            <Grid item md={12} xs={12} className={classes.contentButton}>
              <label htmlFor="raised-button-file">
                <Button
                  component="span"
                  className={classes.button}
                  onClick={() => generateTable()}
                >
                  EXPORTAR .XLSX / .CSV
                </Button>
              </label>
            </Grid>
          </Grid>
        )}

        {showTableExport && (
          <>
            <ReactHTMLTableToExcel
              className={classes.export}
              table={`table-to-xls`}
              filename={`usuarias-filtro-${periodoFilter}`}
              sheet="tablexls"
              buttonText="Exportar CVS"
              id="raised-button-file"
            />
            <table id="table-to-xls">
              <tr>
                <th>Días de mora: {moraFilter}</th>
                <th>Periodo: {periodoFilter}</th>
              </tr>
              <tr>
                <th>Nombre</th>
                <th>Teléfono</th>
              </tr>
              {_.map(filterData, (itemClient) => (
                <tr key={itemClient._id}>
                  <th>{itemClient.firstName}</th>
                  <th> {itemClient.phoneNumber}</th>
                </tr>
              ))}
            </table>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export { FilterUsers };
