/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '13px !important',
    '& :hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('lg')]: {
      padding: '10px !important'
    }
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  }
}));

export { useStyles };
