/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { CuotesPaymentContext } from './CuotesPaymentContext';
import CuotesPaymentReducer from './CuotesPaymentReducer';

import { useApi } from '../../Hooks';
import { CHART_PAYMENT, TIME_CHART } from '../Types';

const CuotesPaymentState = (props) => {
  const initialState = {
    listChart: null,
    timePaimentChart: null
  };

  const [getGraphicsPaymentCuotes] = useApi({
    endpoint: 'api/v1/dashboard/clients',
    method: 'get'
  });

  const [state, dispatch] = useReducer(CuotesPaymentReducer, initialState);

  const getCharts = async (finance) => {
    const responseSecond = await getGraphicsPaymentCuotes({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${finance}/graphics-installments-paid`
    });
    dispatch({ type: TIME_CHART, payload: responseSecond.data });
    const response = await getGraphicsPaymentCuotes({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${finance}/graphics-credit`
    });
    dispatch({ type: CHART_PAYMENT, payload: response.data });
  };

  return (
    <CuotesPaymentContext.Provider
      value={{
        listChart: state.listChart,
        timePaimentChart: state.timePaimentChart,
        getCharts
      }}
    >
      {props.children}
    </CuotesPaymentContext.Provider>
  );
};

export { CuotesPaymentState };
