/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {},
  input: {
    display: 'none'
  }
}));

export { useStyles };
