/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { BehaviorContext } from './BehaviorContext';
import BehaviorReducer from './BehaviorReducer';

import { useApi } from '../../Hooks';
import { ACTIVITY_CHART } from '../Types';

const BehaviorState = (props) => {
  const initialState = {
    activityChart: null
  };

  const [getActivityChart] = useApi({
    endpoint: 'api/v1/dashboard/clients',
    method: 'get'
  });

  const [state, dispatch] = useReducer(BehaviorReducer, initialState);

  const getActivitiChart = async (finance) => {
    const response = await getActivityChart({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${finance}/graphics-behavior`
    });
    dispatch({ type: ACTIVITY_CHART, payload: response.data });
  };

  return (
    <BehaviorContext.Provider
      value={{
        activityChart: state.activityChart,
        getActivitiChart
      }}
    >
      {props.children}
    </BehaviorContext.Provider>
  );
};

export { BehaviorState };
