/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { CursesContext } from './CursesContext';
import CursesReducer from './CursesReducer';

import { useApi } from '../../Hooks';
import { LIST_CURSES, LIST_CLIENTS } from '../Types';

const CursesState = (props) => {
  const initialState = {
    listCurses: null,
    listVideo: null
  };

  const [getallCurses] = useApi({
    endpoint: 'api/v1/dashboard/course',
    method: 'get'
  });
  const [editVideo] = useApi({
    endpoint: 'api/v1/dashboard/videos',
    method: 'put'
  });
  const [addVideo] = useApi({
    endpoint: 'api/v1/dashboard/videos',
    method: 'post'
  });
  const [deleteVide] = useApi({
    endpoint: 'api/v1/dashboard/videos',
    method: 'delete'
  });
  const [updateCurses] = useApi({
    endpoint: 'api/v1/dashboard/course',
    method: 'put'
  });
  const [deleteCurses] = useApi({
    endpoint: 'api/v1/dashboard/course',
    method: 'delete'
  });
  const [addCurses] = useApi({
    endpoint: 'api/v1/dashboard/course',
    method: 'post'
  });

  const [state, dispatch] = useReducer(CursesReducer, initialState);

  const getCusrses = async () => {
    const response = await getallCurses({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL
    });
    dispatch({ type: LIST_CURSES, payload: response.data });
  };
  const getVideosCourse = async (idCourse) => {
    const response = await getallCurses({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/content/${idCourse}`,
      message: false
    });
    dispatch({ type: LIST_CLIENTS, payload: response.data });
  };
  const changeStar = async (values) => {
    const formData = new FormData();

    Object.keys(values).map(
      (itemKey) =>
        values[itemKey] !== null && formData.append(itemKey, values[itemKey])
    );

    const response = await editVideo({
      body: formData,
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${values.id}`,
      message: true
    });
    setTimeout(async () => {
      if (response.message.includes('éxito')) getVideosCourse(values.curse);
    }, 2000);
  };

  const createVideo = async (values) => {
    const formData = new FormData();

    Object.keys(values).map(
      (itemKey) =>
        values[itemKey] !== null && formData.append(itemKey, values[itemKey])
    );

    const response = await addVideo({
      body: formData,
      url: process.env.REACT_APP_BACKEND_URL,
      message: true
    });
    setTimeout(async () => {
      if (response.message.includes('correctamente'))
        getVideosCourse(values.course_id);
    }, 2000);
  };

  const deleteVideo = async (itemVideo) => {
    const response = await deleteVide({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      message: true,
      urlParams: `/${itemVideo._id}`
    });
    setTimeout(async () => {
      if (response.message.includes('correctamente'))
        getVideosCourse(itemVideo.course_id);
    }, 2000);
  };
  const updateCurse = async (values) => {
    const { idCurse } = values;
    const response = await updateCurses({
      body: values,
      url: process.env.REACT_APP_BACKEND_URL,
      message: true,
      urlParams: `/${idCurse}`
    });
    setTimeout(async () => {
      if (response.message.includes('Actualizado')) getCusrses();
    }, 2000);
  };
  const deleteCurse = async (idCurse) => {
    const response = await deleteCurses({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      message: true,
      urlParams: `/${idCurse}`
    });
    setTimeout(async () => {
      if (response.message.includes('Eliminado')) getCusrses();
    }, 2000);
  };
  const createCurse = async (dataCurse) => {
    const response = await addCurses({
      body: dataCurse,
      url: process.env.REACT_APP_BACKEND_URL,
      message: true
    });
    setTimeout(async () => {
      if (response.message.includes('correctamente')) getCusrses();
    }, 2000);
  };

  return (
    <CursesContext.Provider
      value={{
        listCurses: state.listCurses,
        listVideo: state.listVideo,
        getCusrses,
        getVideosCourse,
        changeStar,
        createVideo,
        deleteVideo,
        updateCurse,
        deleteCurse,
        createCurse
      }}
    >
      {props.children}
    </CursesContext.Provider>
  );
};

export { CursesState };
