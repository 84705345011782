/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  input: { display: 'none' },
  mainContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '50px'
  },
  iconButton: {
    backgroundColor: 'white !important',
    '& :hover': {
      color: theme.palette.primary.main
    },
    marginLeft: '25px !important'
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  },
  label: {
    textAlign: 'center',
    fontSize: '20px',
    cursor: 'pointer',
    marginBottom: '25px'
  },
  contenInput: {
    marginTop: '20px'
  },
  button: {
    margin: '2vh !important'
  },
  contentBut: {
    backgroundColor: 'white',
    borderRadius: '40px',
    marginTop: '3vh !important',
    display: 'flex',
    justifyContent: 'center'
  },
  center: {
    marginTop: '20px',
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'center'
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center'
  }
}));

export { useStyles };
