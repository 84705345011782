/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useStyles } from './UsersAdminStyles';
import { UsersAdminContext } from '../../Context/UsersAdmin/UsersAdminContext';
import { Modal } from '../Modal';
import { getItem } from '../../utils/persistentStorage';
import { AddUser } from './AddUser';
import { Input } from '../Input';
import { Finances } from '../Finances';
import { Table } from '../Table';
import { mapData, headers } from './utils';

const UsersAdmin = () => {
  const classes = useStyles();
  const {
    getUsers,
    listUsers,
    createNewUser,
    deleteUser,
    getRoles,
    listRoles,
    updateUser
  } = useContext(UsersAdminContext);
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [updatePassword, setupdatePassword] = useState(false);
  const [idDelete, setidDelete] = useState(false);
  const [addUserForm, setaddUserForm] = useState(false);
  const [newPassword, setnewPassword] = useState('');
  const [openTab, setopenTab] = useState('Usuarios');
  const optionsTab = ['Usuarios', 'Compañias'];

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  const handleDelete = (id) => {
    setconfirmDelete(true);
    setidDelete(id);
  };
  const session = getItem('session');
  const updateRole = (value, idUser) => updateUser({ idUser, rol: value });
  const updateNewPassword = async () => {
    await updateUser({ idUser: updatePassword, password: newPassword });
    setupdatePassword(false);
    setnewPassword('');
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Grid item md={5}>
          <Typography className={classes.titleMetrics}>
            <strong>MI PERFIL </strong> - ADA Impacto
          </Typography>
        </Grid>
      </Grid>
      <Typography className={classes.userEmail}>{session.email}</Typography>
      <Typography
        className={classes.changePassword}
        onClick={() => setupdatePassword(session.id)}
      >
        Cambiar contraseña
      </Typography>
      <Grid container>
        {optionsTab.map((option) => (
          <Grid
            item
            md={12 / optionsTab.length}
            xs={12 / optionsTab.length}
            key={option}
            onClick={() => setopenTab(option)}
          >
            <Typography
              className={
                openTab === option
                  ? classes.titleOption
                  : classes.titleOptionDisabled
              }
            >
              {option}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {openTab === 'Compañias' ? (
        <Finances />
      ) : (
        <>
          {listUsers !== null && (
            <Table
              body={mapData(
                listUsers,
                listRoles,
                updateRole,
                session,
                classes,
                setupdatePassword,
                handleDelete,
                () => setaddUserForm(true)
              )}
              headers={headers}
            />
          )}

          <Modal
            modal={{
              show: confirmDelete,
              changeVisibility: () => setconfirmDelete(false),
              close: () => setconfirmDelete(false)
            }}
          >
            <Grid className={classes.contentModal}>
              <Typography
                style={{
                  fontSize: '30px',
                  fontWeight: 800
                }}
              >
                Eliminar Usuario
              </Typography>
              <Typography
                style={{
                  fontSize: '20px'
                }}
              >
                Seguro que deseas eliminar este usuario
              </Typography>
            </Grid>
            <Grid container>
              <Grid item md={6} className={classes.centerButtom}>
                <Button
                  onClick={() => setconfirmDelete(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item md={6} className={classes.centerButtom}>
                <Button color="secondary" onClick={() => deleteUser(idDelete)}>
                  Eliminar
                </Button>
              </Grid>
            </Grid>
          </Modal>
          <Modal
            modal={{
              show: addUserForm,
              changeVisibility: () => setaddUserForm(false),
              close: () => setaddUserForm(false)
            }}
          >
            <AddUser
              createUser={(values) => createNewUser(values)}
              roles={listRoles}
              closeModal={() => setaddUserForm(false)}
            />
          </Modal>
        </>
      )}
      <Modal
        modal={{
          show: updatePassword,
          changeVisibility: () => setupdatePassword(false),
          close: () => setupdatePassword(false)
        }}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <Typography
            style={{
              fontSize: '30px',
              fontWeight: 800,
              marginBottom: '1vh'
            }}
          >
            Actualizar contraseña
          </Typography>
          <Input
            placeholder="Titulo"
            value={newPassword}
            onChange={(e) => setnewPassword(e.target.value)}
            id="title"
            label="Contraseña"
            type="text"
            inputProps={{
              minLength: 8,
              maxLength: 50,
              autoComplete: 'off'
            }}
            error={newPassword === ''}
            helperText={newPassword === '' && 'Ingresa una contraseña'}
          />
        </Grid>
        <Grid container>
          <Grid item md={6} className={classes.centerButtom}>
            <Button
              onClick={() => setupdatePassword(false)}
              variant="contained"
              color="primary"
            >
              Cerrar
            </Button>
          </Grid>
          <Grid item md={6} className={classes.centerButtom}>
            <Button
              color="secondary"
              onClick={() => updateNewPassword()}
              disabled={newPassword.length < 8}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export { UsersAdmin };
