const optionsMora = [
  { value: 1, label: '1 Día después de la fecha de pago' },
  { value: 5, label: '5 Días después de la fecha de pago' },
  { value: 9, label: '9 Días después de la fecha de pago' },
  { value: 13, label: '13 Días después de la fecha de pago' },
  { value: 17, label: '17 Días después de la fecha de pago' },
  { value: 25, label: '25 Días después de la fecha de pago' },
  { value: 30, label: '30 Días después de la fecha de pago' },
  { value: 31, label: '31 Días o mas después de la fecha de pago' }
];

const optionsCredit = [
  { value: 1, label: 'revolvente' },
  { value: 5, label: 'tradicional' }
];
const paymentPeriod = [
  { value: 8, label: 'Semanal' },
  { value: 15, label: 'Quincenal' },
  { value: 31, label: 'Mensual' }
];
const headers = [
  {
    key: 'customer',
    label: 'Nombre'
  },
  { key: 'name', label: 'Tèlefono' }
];
const createData = (options) => {
  return options.map((option) => [
    { key: 'customer', content: option.firstName, type: 'first' },
    { key: 'name', content: option.phoneNumber, type: 'latest' }
  ]);
};

export { paymentPeriod, optionsCredit, optionsMora, headers, createData };
