/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  titleMetrics: {
    color: theme.palette.primary.main,
    fontSize: '22px !important'
  },
  containerSelector: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  titleSelector: {
    textAlign: 'center',
    fontSize: '14px  !important',
    fontWeight: '800 !important'
  },
  userEmail: {
    fontSize: '50px !important ',
    marginTop: '5vh !important',
    fontWeight: '800 !important',
    color: theme.palette.primary.main
  },
  changePassword: {
    fontSize: '20px !important ',
    marginTop: '1vh !important',
    fontWeight: '800 !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  first: {
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '48px 0px 0px 48px',
    margin: '10px !important',
    padding: '15px'
  },
  centerItem: {
    textAlign: 'center',
    backgroundColor: 'white',
    margin: '10px !important',
    padding: '15px 6px'
  },
  lastItem: {
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '0px 48px 48px 0px',
    margin: '10px !important',
    padding: '15px 6px'
  },
  label: { fontSize: '14px !important', fontWeight: '800!important' }
}));

export { useStyles };
