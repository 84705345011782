import * as yup from 'yup';

const validationSchema = yup.object({
  description: yup
    .string('se requiere una descripcciòn con letras')
    .min(10, 'se requiere una descripcciòn con almenos 10 caracteres')
    .required('La descripcciòn es requerida'),
  title: yup
    .string('Se requiere un correo con letras')
    .required('El titulo es un campo obligatorio'),
  order: yup
    .string('Se requiere un correo con letras')
    .required('El orden es un campo obligatorio')
});

export { validationSchema };
