/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { FilterUsersContext } from './FilterUsersContext';
import FilterUserReducer from './FilterUsersReducer';

import { useApi } from '../../Hooks';
import { LIST_CLIENTS } from '../Types';

const FilterUsersState = (props) => {
  const initialState = {
    listclients: null
  };

  const [getallClients] = useApi({
    endpoint: 'api/v1/dashboard/clients/',
    method: 'get'
  });

  const [state, dispatch] = useReducer(FilterUserReducer, initialState);

  const getMoraClients = async (values) => {
    const { mora, period, finance } = values;
    let response;
    if (mora !== null && period !== null) {
      response = await getallClients({
        body: {},
        url: process.env.REACT_APP_BACKEND_URL,
        urlParams: `${finance}/filters/${mora}/${period}`
      });
    } else if (mora !== null && period === null) {
      response = await getallClients({
        body: {},
        url: process.env.REACT_APP_BACKEND_URL,
        urlParams: `${finance}/filter/arrears/${mora}`
      });
    } else if (mora === null && period !== null) {
      response = await getallClients({
        body: {},
        url: process.env.REACT_APP_BACKEND_URL,
        urlParams: `${finance}/filter/frequency/${period}`
      });
    }
    dispatch({ type: LIST_CLIENTS, payload: response.data });
  };

  return (
    <FilterUsersContext.Provider
      value={{
        listclients: state.listclients,
        getMoraClients
      }}
    >
      {props.children}
    </FilterUsersContext.Provider>
  );
};

export { FilterUsersState };
