import { GET_FINANCES } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_FINANCES:
      return {
        ...state,
        financesList: payload
      };

    default:
      return state;
  }
};
