/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { useStyles } from './VideoListStyles';
import PropTypes from 'prop-types';
import { Add, Visibility, Delete, Star } from '@mui/icons-material/';
import { DetailVideo } from './DetailVideo';
import { Modal } from '../../Modal';
import _ from 'lodash';
import { Table } from '../../Table';
import { Select } from '../../Select';

const VideoList = ({
  listVideo,
  listCurses,
  changeStatus,
  curseId,
  createCurse,
  deleteVideo
}) => {
  const classes = useStyles();
  const headers = [
    {
      key: 'customer',
      label: 'Customer'
    },
    { key: 'name', label: 'Name' },
    { key: 'sales', label: 'Sales Org.' },
    { key: 'distrit', label: 'Dsitr. Chanel.' },
    { key: 'type', label: 'Dsitr. Chanel.' }
  ];

  const typeShow = [
    { value: true, label: 'Visble' },
    { value: false, label: 'No visible' }
  ];
  const nameCurse = listCurses.map((itemCurse) => {
    if (_.get(listVideo, [0], null) !== null) {
      if (itemCurse._id === listVideo[0].course_id) return itemCurse;
    }
  });
  const [selectedVideo, setselectedVideo] = useState(null);
  const [confirmDelete, setconfirmDelete] = useState(null);
  const [addVideo, setaddVideo] = useState(null);

  const createData = (data) => {
    return data.map((itemVideo) => [
      {
        key: 'customer',
        type: 'first',
        content: (
          <Grid container>
            <Grid item md={10}>
              {itemVideo.title}
            </Grid>
            <Grid item md={2}>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  changeStatus({
                    id: itemVideo._id,
                    featured: !itemVideo.featured,
                    curse: itemVideo.course_id
                  })
                }
              >
                <Star
                  className={
                    itemVideo.featured
                      ? classes.iconstart
                      : classes.icondisabled
                  }
                />
              </Typography>
            </Grid>
          </Grid>
        )
      },
      {
        key: 'name',
        content: (
          <Select
            value={itemVideo.course_id}
            options={listCurses.map((item) => {
              return { label: item.name, value: item._id };
            })}
            onChange={(e) =>
              changeStatus({
                id: itemVideo._id,
                curse: itemVideo.course_id,
                course_id: e.target.value
              })
            }
          />
        )
      },
      {
        key: 'sales',
        type: 'latest',
        content: (
          <Select
            value={itemVideo.visible}
            options={typeShow}
            onChange={(e) =>
              changeStatus({
                id: itemVideo._id,
                curse: itemVideo.course_id,
                visible: e.target.value
              })
            }
          />
        )
      },
      {
        key: 'distrit',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => setselectedVideo(itemVideo)}
          >
            <Visibility className={classes.icon} />
          </IconButton>
        )
      },
      {
        key: 'type',
        type: 'button',
        content: (
          <IconButton
            className={classes.iconButton}
            onClick={() => deleteVideo(itemVideo)}
          >
            <Delete className={classes.icon} />
          </IconButton>
        )
      }
    ]);
  };
  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Grid item md={11}>
          <Typography className={classes.title}>
            <span
              className={classes.link}
              onClick={() => (window.location = 'Videos')}
            >
              CURSOS Y VÍDEOS
            </span>
            -&nbsp;
            {nameCurse.map(
              (itemCurse) => itemCurse !== undefined && itemCurse.name
            )}
          </Typography>
        </Grid>
        <Grid
          item
          md={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <IconButton
            className={classes.iconButton}
            onClick={() => setaddVideo(true)}
          >
            <Add className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid>
        {listVideo.map((itemVideo) => (
          <Grid container key={itemVideo._id}>
            {selectedVideo !== null && selectedVideo._id === itemVideo._id && (
              <DetailVideo
                videoDetail={selectedVideo}
                closeDetail={() => setselectedVideo(null)}
                updateVideo={(values) =>
                  changeStatus({
                    id: itemVideo._id,
                    curse: curseId,
                    ...values
                  })
                }
              />
            )}
          </Grid>
        ))}
        <Table headers={headers} body={createData(listVideo)} />
      </Grid>
      <Modal
        modal={{
          show: confirmDelete,
          changeVisibility: () => setconfirmDelete(false),
          close: () => setconfirmDelete(false)
        }}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <Typography
            style={{
              fontSize: '30px',
              fontWeight: 800
            }}
          >
            Eliminar Curso
          </Typography>
          <Typography
            style={{
              fontSize: '20px'
            }}
          >
            Seguro que deseas eliminar este curso
          </Typography>
        </Grid>
      </Modal>
      <Modal
        modal={{
          show: addVideo,
          changeVisibility: () => setaddVideo(false),
          close: () => setaddVideo(false)
        }}
      >
        <Grid style={{ textAlign: 'center', marginBottom: '2vh' }}>
          <Typography
            style={{
              fontSize: '30px',
              fontWeight: 800
            }}
          >
            Agregar nuevo video
          </Typography>
          <DetailVideo
            type="createVideo"
            updateVideo={(values) =>
              createCurse({
                course_id: curseId,
                visible: false,
                featured: false,
                ...values
              })
            }
            closeDetail={() => setaddVideo(null)}
          />
        </Grid>
      </Modal>
    </Grid>
  );
};
VideoList.propTypes = {
  listVideo: PropTypes.object,
  listCurses: PropTypes.object,
  changeStatus: PropTypes.func,
  curseId: PropTypes.string,
  createCurse: PropTypes.func,
  deleteVideo: PropTypes.func
};
export { VideoList };
