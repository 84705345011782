/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  }
}));

export { useStyles };
