/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minHeight: '100vh',
    background:
      'transparent linear-gradient(270deg, #FF8600 0%, #EA285D 100%) 0% 0%',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  titleLogin: {
    color: theme.palette.primary.main,
    fontSize: '30px !important',
    fontWeight: '800 !important'
  },
  subtitleLogin: {
    color: theme.palette.primary.main,
    fontSize: '25px !important',
    marginBottom: '4vh !important'
  },
  leyend: {
    color: '#F2ECE4',
    fontSize: '100px !important',
    textAlign: 'right',
    fontWeight: '800 !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '30px !important',
      textAlign: 'center'
    }
  },
  containerInputs: {
    padding: '12vh 5vh',
    backgroundColor: '#F2ECE4',
    borderRadius: '57px',
    marginTop: '50px',
    height: '866px',
    [theme.breakpoints.down('md')]: {
      padding: '5vh 4vh',
      height: '80vh'
    },
    [theme.breakpoints.up('xl')]: {
      padding: '5vh 4vh',
      height: 'auto',
      marginTop: '10vh !important'

      //height: '90vh'
    }
  },
  inputContent: {
    marginTop: '20px !important',
    marginBottom: '25px !important',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px !important',
      marginBottom: '10px !important'
    }
  },
  button: {
    color: '#575756 !important',
    fontSize: '35px !important',
    fontWeight: '800 !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '25px !important'
    }
  },
  contentButon: {
    backgroundColor: 'white !important',
    borderRadius: '40px !important',
    marginTop: '42px !important',
    [theme.breakpoints.down('md')]: {
      marginTop: '22px !important'
    }
  }
}));

export { useStyles };
