/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import { useStyles } from './DetailVideoStyles';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { VisibilityOff } from '@mui/icons-material/';
import { Input } from '../../../Input';
import { validationSchema } from './utils';

const DetailVideo = ({ videoDetail, updateVideo, closeDetail, type }) => {
  const classes = useStyles();
  const [imageUpdate, setimageUpdate] = useState(null);
  const [videoFile, setvideoFile] = useState(null);
  const [errorVideo, seterrorVideo] = useState(null);
  const [errorImage, seterrorImage] = useState(null);
  const [ImageBase, setImageBase] = useState(null);
  const [videoBase, setvideoBase] = useState(null);

  const selectImage = (event) => {
    if (event.target.files[0].name.match(/\.(jpeg|png)$/) !== null) {
      var reader = new FileReader();
      reader.onloadend = function () {
        setImageBase(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setimageUpdate(event.target.files[0]);
      seterrorImage(null);
    } else {
      seterrorImage('selecicona una imagen valida');
    }
  };
  const selectVideo = (event) => {
    if (event.target.files[0].name.match(/\.(mp4)$/) !== null) {
      setvideoFile(event.target.files[0]);
      var reader = new FileReader();
      reader.onloadend = function () {
        setvideoBase(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      seterrorVideo(null);
    } else {
      seterrorVideo('No se puede actualizar el video');
    }
  };

  const formik = useFormik({
    initialValues: {
      title: videoDetail ? videoDetail.title : '',
      description: videoDetail ? videoDetail.description : '',
      order: videoDetail ? videoDetail.order : 0
    },
    validationSchema,
    onSubmit: async (values) => {
      if (type === 'createVideo') {
        if (videoFile !== null && imageUpdate !== null) {
          await uploadVideo(values, updateVideo);
          closeDetail();
        }
      } else {
        await uploadVideo(values, updateVideo);
        closeDetail();
      }
    }
  });

  const uploadVideo = (values, funcSend) => {
    funcSend({
      ...values,
      thumbnail: imageUpdate,
      video: videoFile
    });
  };

  return (
    <Grid container>
      <Grid md={11} className={classes.mainContainer}>
        <form
          className={classes.form}
          key="Login"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <Grid container>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item md={6} className={classes.contenInput}>
                <Input
                  placeholder="Titulo"
                  type="text"
                  label="Titulo"
                  id="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  helperText={formik.touched.title && formik.touched.title}
                />
              </Grid>
              <Grid item md={6} className={classes.contenInput}>
                <Input
                  placeholder="Orden"
                  type="number"
                  label="Orden"
                  id="order"
                  value={formik.values.order}
                  onChange={formik.handleChange}
                  helperText={formik.touched.order && formik.touched.order}
                  inputProps={{
                    min: 1,
                    max: 500,
                    autoComplete: 'off'
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Input
                  placeholder="Descripcciòn"
                  type="text"
                  label="Descripcciòn"
                  id="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.description && formik.touched.description
                  }
                  inputProps={{
                    minLength: 6,
                    maxLength: 500,
                    autoComplete: 'off'
                  }}
                  multiline
                  minRows={3}
                />
              </Grid>

              <Grid item md={6} xs={12} className={classes.center}>
                <input
                  accept=".jpeg,.png"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={selectImage}
                />
                <label htmlFor="contained-button-file">
                  {videoDetail ? (
                    <Grid className={classes.containerButton}>
                      <img
                        alt="Upload-icon"
                        style={{
                          height: '200px',
                          marginRight: '10px',
                          cursor: 'pointer'
                        }}
                        src={videoDetail.thumbnail}
                      />
                      <Button
                        variant="outlined"
                        component="span"
                        className={classes.button}
                      >
                        Cambiar imagen
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        component="span"
                        className={classes.button}
                      >
                        Selecciona una imagen
                      </Button>
                      {imageUpdate !== null && (
                        <img src={ImageBase} alt={formik.values.title} />
                      )}
                    </>
                  )}
                  {errorVideo && (
                    <Typography style={{ color: 'red' }}>
                      {errorVideo}
                    </Typography>
                  )}
                </label>
              </Grid>
              <Grid item md={6} xs={12} className={classes.center}>
                <input
                  accept=".mp4"
                  className={classes.input}
                  id="contained-video-file"
                  type="file"
                  onChange={selectVideo}
                />
                <label htmlFor="contained-video-file">
                  {videoDetail ? (
                    <Grid className={classes.containerButton}>
                      <video
                        controls
                        height="200px"
                        src={videoDetail && videoDetail.video}
                      ></video>
                      <Button
                        variant="outlined"
                        component="span"
                        className={classes.button}
                      >
                        Cambiar video
                      </Button>
                    </Grid>
                  ) : (
                    <Grid className={classes.containerButton}>
                      <Button variant="outlined" component="span">
                        Selecciona un video
                      </Button>
                      {videoBase && (
                        <video
                          controls
                          height="200px"
                          src={videoBase && videoBase}
                        ></video>
                      )}
                    </Grid>
                  )}
                  {errorImage && (
                    <Typography style={{ color: 'red' }}>
                      {errorImage}
                    </Typography>
                  )}
                </label>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10} md={9} className={classes.contentBut}>
                <Button type="submit" variant="contained">
                  {type !== 'createVideo' ? 'Actualizar' : 'Agregar'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {type !== 'createVideo' && (
        <Grid item md={1}>
          <IconButton
            className={classes.iconButton}
            onClick={() => closeDetail()}
            style={{}}
          >
            <VisibilityOff className={classes.icon} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

DetailVideo.propTypes = {
  videoDetail: PropTypes.object,
  listCurses: PropTypes.object,
  updateVideo: PropTypes.func,
  closeDetail: PropTypes.func,
  type: PropTypes.string
};
export { DetailVideo };
