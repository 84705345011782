/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { CreditContext } from './CreditContext';
import CreditReducer from './CreditReducer';

import { useApi } from '../../Hooks';
import { CHART_CREDIT } from '../Types';

const CreditState = (props) => {
  const initialState = {
    graphicOdds: null
  };

  const [getDataAnalitycs] = useApi({
    endpoint: 'api/v1/dashboard/clients/progresemos/graphics-credit',
    method: 'get'
  });

  const [state, dispatch] = useReducer(CreditReducer, initialState);

  const getChartOdds = async () => {
    const response = await getDataAnalitycs({
      body: {},
      message: false,
      url: process.env.REACT_APP_BACKEND_URL
    });
    dispatch({ type: CHART_CREDIT, payload: response.data });
  };
  return (
    <CreditContext.Provider
      value={{
        graphicOdds: state.graphicOdds,
        getChartOdds
      }}
    >
      {props.children}
    </CreditContext.Provider>
  );
};

export { CreditState };
