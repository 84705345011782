/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { FinancesContext } from './FilterContext';
import LogginReducer from './FilterReducer';
import { useApi } from '../../Hooks';
import { GET_FINANCES } from '../Types';
import { getItem, setItem } from '../../utils/persistentStorage';

const FinancesState = (props) => {
  const initialState = {
    financesList: null
  };

  const [getAllFinances] = useApi({
    endpoint: 'api/v1/dashboard/companies/financials',
    method: 'get'
  });

  const [state, dispatch] = useReducer(LogginReducer, initialState);

  const getFinaces = async () => {
    if (getItem('finances') === null) {
      const response = await getAllFinances({
        body: {},
        message: false,
        url: process.env.REACT_APP_BACKEND_URL
      });
      setItem('finances', response.data);

      dispatch({ type: GET_FINANCES, payload: response.data });
    } else {
      dispatch({ type: GET_FINANCES, payload: getItem('finances') });
    }
  };

  return (
    <FinancesContext.Provider
      value={{
        financesList: state.financesList,
        getFinaces
      }}
    >
      {props.children}
    </FinancesContext.Provider>
  );
};

export { FinancesState };
