import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '../Components/Menu';

const BasicLayout = ({ children, routes }) => {
  return <Menu routes={routes} content={children} />;
};
BasicLayout.propTypes = {
  children: PropTypes.object,
  routes: PropTypes.object
};

export { BasicLayout };
