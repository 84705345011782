import { CHARTS_HOME, HEADER_ANALYTICS_HOME, CHART_DISBURSED } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case CHARTS_HOME:
      return {
        ...state,
        chartsHome: payload
      };
    case HEADER_ANALYTICS_HOME:
      return {
        ...state,
        headerAnalityc: payload
      };
    case CHART_DISBURSED:
      return {
        ...state,
        chartDisbursed: payload
      };

    default:
      return state;
  }
};
