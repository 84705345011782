/* eslint-disable no-undef */
import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useStyles } from './LoginStyles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LogginContext } from '../../Context/Loggin/LogginContext';
import { Input } from '../Input';

const Login = () => {
  const { handleLoggin } = useContext(LogginContext);

  const classes = useStyles();
  const validationSchema = yup.object({
    password: yup
      .string('se requiere una contraseña con letras')
      .min(6, 'se requiere una contraseña con almenos 6 caracteres')
      .required('La contraseña es requerida'),
    email: yup
      .string('Se requiere un correo con letras')
      .email('Se requiere un correo con un formato valido')
      .required('El correo es un campo obligatorio')
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: (values) => {
      handleLoggin(values);
    }
  });
  return (
    <Grid className={classes.rootContainer}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={10}
          md={6}
          style={{
            padding: '2vh',
            marginRight: '2vh',
            marginTop: '107px'
          }}
        >
          <Typography className={classes.leyend}>
            Bienvenido/a a tu dashboard de ADA Impacto
          </Typography>
        </Grid>
        <Grid item xs={10} md={4} className={classes.containerInputs}>
          <Typography className={classes.titleLogin}>LOGIN</Typography>
          <Typography className={classes.subtitleLogin}>
            Introduce tu usuario y contraseña
          </Typography>
          <form
            className={classes.form}
            key="Login"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Grid container>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item md={12} className={classes.inputContent}>
                  <Input
                    placeholder="Usuario"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    id="email"
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item md={12} className={classes.inputContent}>
                  <Input
                    placeholder="Contraseña"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    id="password"
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    inputProps={{
                      minLength: 6,
                      maxLength: 50,
                      autoComplete: 'off'
                    }}
                    typeForm="loggin"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={10} md={9} className={classes.contentButon}>
                  <Button
                    className={classes.button}
                    fullWidth
                    type="submit"
                    id="button-login"
                  >
                    ¡ENTRAR!
                  </Button>
                </Grid>
                <Grid style={{ marginTop: '10vh' }}>
                  <Typography>
                    ¿Tienes problemas para entrar?{' '}
                    <strong style={{ cursor: 'pointer' }}>Escríbenos</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { Login };
