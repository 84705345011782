import { makeStyles, withStyles } from '@mui/styles';
import { TableRow } from '@mui/material';

const optionsCell = {
  textAlign: 'center !important'
};

const StyledTableRow = withStyles(() => ({
  root: {
    margin: '10px !important',
    fontSize: '40px !important'
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  export: {
    display: 'none'
  },

  container: {
    padding: theme.spacing(0.1)
  },
  pagination: {
    '& .MuiPagination-ul li .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  totalSum: {
    '& td': {
      margin: '0.5vh !important',
      fontSize: '15px !important',
      padding: '0px !important',
      [theme.breakpoints.down('md')]: {
        padding: '15px !important'
      },
      borderBottom: 'none !important'
    },
    '&.MuiTable-root': {
      borderSpacing: '1vh !important',
      borderCollapse: 'inherit'
    }
  },
  total: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    '& td': {
      color: 'white'
    }
  },
  first: {
    borderRadius: '48px 0px 0px 48px',
    backgroundColor: 'white !important',
    ...optionsCell
  },
  button: {
    ...optionsCell,
    padding: 'none !important'
  },
  midle: {
    marginTop: '100px !important',
    backgroundColor: 'white !important',
    ...optionsCell
  },
  last: {
    backgroundColor: 'white !important',
    borderRadius: '0px 48px 48px 0px',
    ...optionsCell
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2vh'
  }
}));

export { useStyles, StyledTableRow };
