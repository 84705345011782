import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import {
  Grid,
  TableCell,
  Table as TableMat,
  TableBody,
  TableContainer
} from '@mui/material';
import { useStyles, StyledTableRow } from './TableStyles';

const Table = ({ id, headers, body, nextPage, activePage, filterValue }) => {
  const classes = useStyles();
  const validateClass = (type) => {
    switch (type) {
      case 'first':
        return classes.first;
      case 'latest':
        return classes.last;
      case 'button':
        return classes.button;

      default:
        return classes.midle;
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    nextPage({
      page: newPage,
      rowsPerPage
    });
    window.scrollTo(0, 0);
  };
  const handleTableCells = (row) => {
    const createOptions = [];
    _.forEach(headers, (headerItem, index) => {
      const finded = _.find(row, (rowCell) => headerItem.key === rowCell.key);

      createOptions.push(
        <TableCell
          key={`content-row-table-${index}`}
          align={headerItem.align}
          className={validateClass(_.get(finded, 'type', ''))}
        >
          {_.get(finded, 'content', '')}
        </TableCell>
      );
    });
    return createOptions;
  };

  useEffect(() => {
    setPage(0);
  }, [filterValue]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={id}>
        <TableContainer>
          <TableMat
            className={classes.totalSum}
            style={{ 'border-spacing': '100vh !important' }}
            id={`table-to-xls${id}`}
          >
            <TableBody>
              {_.map(
                body.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
                (row, index) => (
                  <StyledTableRow
                    tabIndex={1}
                    key={`table-row-content-${index}`}
                  >
                    {handleTableCells(row)}
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </TableMat>
        </TableContainer>
        {body.length > rowsPerPage && (
          <Grid className={classes.paginationContainer}>
            <Pagination
              count={Math.ceil(body.length / rowsPerPage)}
              shape="rounded"
              onChange={handleChangePage}
              page={activePage || page + 1}
              color="primary"
              className={classes.pagination}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

Table.propTypes = {
  id: PropTypes.string,
  headers: PropTypes.array,
  body: PropTypes.array,
  pagination: PropTypes.bool,
  showHeaders: PropTypes.bool,
  fileName: PropTypes.string,
  onRowClick: PropTypes.func,
  onCreateDoc: PropTypes.func,
  afterDoc: PropTypes.func,
  nextPage: PropTypes.func,
  totalItems: PropTypes.number,
  activePage: PropTypes.number,
  ButtonLine: PropTypes.node,
  filterValue: PropTypes.string
};

Table.defaultProps = {
  id: '10002',
  pagination: true,
  showHeaders: false,
  headers: [
    {
      key: 'customer',
      label: 'Customer'
    },
    { key: 'name', label: 'Name' },
    { key: 'sales', label: 'Sales Org.' },
    { key: 'distrit', label: 'Dsitr. Chanel.' },
    { key: 'type', label: 'Dsitr. Chanel.' },
    { key: 'text', label: 'Dsitr. Chanel.' },
    { key: 'date', label: 'Dsitr. Chanel.' },
    { key: 'status', label: 'Dsitr. Chanel.' },
    { key: 'menu', label: 'Dsitr. Chanel.' }
  ],
  body: [
    [
      { key: 'customer', content: 'example.id' },
      { key: 'name', content: 'example.name' },
      { key: 'sales', content: 'example.email' },
      { key: 'distrit', content: 'example.tel' },
      { key: 'type', content: 'example.type' },
      { key: 'text', content: 'example.text' },
      { key: 'date', content: 'example.date' },
      { key: 'status', content: 'example.status' },
      { key: 'menu', content: 'example.menu' }
    ]
  ]
};

export { Table };
