import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: '10px'
  },
  boxContent: {
    background: ' white',
    overflow: 'hidden'
  },
  icon: { color: theme.palette.common.white, fontSize: '30px' },
  fontWeightBold: { fontSize: '22px', color: theme.palette.common.white },
  iconSuccess: { color: theme.palette.common.white, fontSize: '50px' },
  iconError: { color: theme.palette.common.white, fontSize: '50px' },
  button: {
    fontSize: '19px',
    padding: theme.spacing(2),
    letterSpacing: '0.42px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    border: '2px solid',
    backgroundColor: theme.palette.common.white,
    fontWeight: '900',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  iconClose: {
    color: 'black',
    fontSize: '30px',
    cursor: 'pointer'
  },
  helpText: {
    color: theme.palette.common.white,
    fontWeight: 800,
    fontSize: '25px'
  }
}));

export { useStyles };
