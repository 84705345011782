import { GET_NOTIFICATIONS } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        listNotification: payload
      };

    default:
      return state;
  }
};
