/* eslint-disable no-undef */
import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useStyles } from './AddUserStyles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { getItem } from '../../../utils/persistentStorage';
import { Input } from '../../Input';
import { Select } from '../../Select';

const AddUser = ({ createUser, roles, closeModal }) => {
  const classes = useStyles();

  const validationSchema = yup.object({
    password: yup
      .string('se requiere una contraseña con letras')
      .min(6, 'se requiere una contraseña con almenos 6 caracteres')
      .required('La contraseña es requerida'),
    email: yup
      .string('Se requiere un correo con letras')
      .email('Se requiere un correo con un formato valido')
      .required('El correo es un campo obligatorio'),
    rol: yup
      .string('Se requiere un rol con letras')
      .required('El rol es un campo obligatorio'),
    companyName: yup
      .string('Se requiere una compañia con letras')
      .required('La compañia es un campo obligatorio')
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rol: '',
      companyName: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      await createUser(values);
      closeModal();
    }
  });
  const enterprises = getItem('finances') === null ? [] : getItem('finances');

  return (
    <Grid className={classes.rootContainer}>
      <form
        className={classes.form}
        key="Login"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h4">Agregar Usuario</Typography>
          </Grid>
          <Grid item md={6}>
            <Input
              placeholder="Correo electrónico"
              label="Correo electrónico"
              inputProps={{
                minLength: 6,
                maxLength: 500,
                autoComplete: 'off'
              }}
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              helperText={formik.touched.email && formik.touched.email}
              id="email"
            />
          </Grid>
          <Grid item md={6}>
            <Input
              placeholder="Contraseña"
              label="Contraseña"
              inputProps={{
                minLength: 6,
                maxLength: 500,
                autoComplete: 'off'
              }}
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              helperText={formik.touched.password && formik.touched.password}
              id="password"
            />
          </Grid>
          <Grid item md={6}>
            <Select
              options={roles.map((item) => {
                return { label: item.name, value: item.name };
              })}
              label="Selecciona el rol"
              placeholder="Nombre de la empresa"
              id="rol"
              name="rol"
              value={formik.values.rol}
              onChange={(event, newInputValue) => {
                formik.handleChange(event, newInputValue);
              }}
              error={formik.touched.rol && Boolean(formik.errors.rol)}
              helperText={formik.touched.rol && formik.errors.rol}
            />
          </Grid>
          <Grid item md={6}>
            <Select
              options={enterprises.map((item) => {
                return {
                  label: item.label ? item.label : item.name,
                  value: item.value ? item.value : item.name
                };
              })}
              value={formik.values.companyName}
              label="Selecciona la empresa"
              placeholder="Nombre de la empresa"
              id="companyName"
              name="companyName"
              onChange={(event, newInputValue) => {
                formik.handleChange(event, newInputValue);
              }}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Grid>
          <Grid
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2vh'
            }}
          >
            <Button type="submit" variant="contained" color="primary">
              Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

AddUser.propTypes = {
  createUser: PropTypes.func,
  roles: PropTypes.array,
  closeModal: PropTypes.func
};

export { AddUser };
