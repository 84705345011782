/* eslint-disable no-undef */
import React from 'react';
import {
  Grid,
  Typography,
  FormControl,
  FilledInput,
  Button
} from '@mui/material';
import { useStyles } from './DetailCurseStyles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

const DetailCurse = ({ curseDetail, updateCurse, closeDetail, type }) => {
  const classes = useStyles();

  const validationSchema = yup.object({
    description: yup
      .string('se requiere una descripcciòn con letras')
      .min(10, 'se requiere una descripcciòn con almenos 10 caracteres')
      .required('La descripcciòn es requerida'),
    title: yup
      .string('Se requiere un correo con letras')
      .required('El titulo es un campo obligatorio'),
    order: yup
      .string('Se requiere un correo con letras')
      .required('El orden es un campo obligatorio')
  });
  const formik = useFormik({
    initialValues: {
      title: curseDetail ? curseDetail.name : '',
      description: curseDetail ? curseDetail.description : '',
      order: curseDetail ? curseDetail.order : 0
    },
    validationSchema,
    onSubmit: async (values) => {
      await updateCurse({ ...values, idCurse: curseDetail._id });
      closeDetail();
    }
  });

  return (
    <Grid container>
      <form
        className={classes.form}
        key="Login"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            md={6}
            style={{
              marginTop: '20px'
            }}
          >
            <Typography className={classes.label}>Titulo</Typography>
            <FormControl fullWidth className={classes.input} color="primary">
              <FilledInput
                placeholder="Titulo"
                type="text"
                value={formik.values.title}
                onChange={formik.handleChange}
                id="title"
                disableUnderline
                label="Titulo"
              />
              {formik.touched.title && (
                <Typography className={classes.helperText}>
                  {formik.errors.title}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              marginTop: '20px'
            }}
          >
            <Typography className={classes.label}>Orden</Typography>
            <FormControl fullWidth className={classes.input} color="primary">
              <FilledInput
                className={classes.input}
                placeholder="Order"
                type={'number'}
                value={formik.values.order}
                onChange={formik.handleChange}
                disableUnderline
                id="order"
                inputProps={{
                  min: 1,
                  max: 500,
                  autoComplete: 'off'
                }}
              />
              {formik.touched.order && (
                <Typography className={classes.helperText}>
                  {formik.errors.order}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.label}>Descripcciòn</Typography>
            <FormControl fullWidth className={classes.input} color="primary">
              <FilledInput
                className={classes.input}
                placeholder="Descripcciòn"
                type={'text'}
                value={formik.values.description}
                onChange={formik.handleChange}
                disableUnderline
                id="description"
                inputProps={{
                  minLength: 6,
                  maxLength: 500,
                  autoComplete: 'off'
                }}
                label="Descripcciòn"
                multiline
                minRows={3}
              />
              {formik.touched.description && (
                <Typography className={classes.helperText}>
                  {formik.errors.description}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={10}
              md={9}
              style={{
                backgroundColor: 'white',
                borderRadius: '40px',
                marginTop: '42px'
              }}
            >
              <Button
                style={{
                  color: '#575756',
                  fontSize: '35px',
                  fontWeight: 800
                }}
                fullWidth
                type="submit"
              >
                {type !== 'createVideo' ? 'Actualizar' : 'Agregar'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

DetailCurse.propTypes = {
  curseDetail: PropTypes.object,
  listCurses: PropTypes.object,
  updateCurse: PropTypes.func,
  closeDetail: PropTypes.func,
  type: PropTypes.string
};
export { DetailCurse };
