import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Dialog, Typography, Button } from '@mui/material';
import { Error, Check, Warning, Close } from '@mui/icons-material';
import { useStyles } from './ModalStyles';

const Modal = ({ children, size, actionButtons, modal }) => {
  const classes = useStyles();
  const {
    show,
    message,
    Icon,
    changeVisibility,
    type,
    close,
    helpText,
    redirect
  } = modal;
  const typeModal = (typeIcon) => {
    let iconShow;

    switch (typeIcon) {
      case 'success':
        iconShow = <Check className={classes.iconSuccess} fontSize="large" />;
        break;
      case 'error':
        iconShow = <Error className={classes.iconError} fontSize="large" />;
        break;
      case 'warning':
        iconShow = <Warning className={classes.iconWarning} fontSize="large" />;
        break;
      case 'close':
        iconShow = <Close className={classes.iconClose} fontSize="large" />;
        break;

      default:
        break;
    }

    return (
      <Box display="flex" justifyContent="center" p={1}>
        {iconShow}
      </Box>
    );
  };

  return (
    <Dialog
      onClose={
        changeVisibility
          ? () => changeVisibility()
          : () => {
              redirect !== '' && redirect();
            }
      }
      open={show}
      maxWidth={size}
      fullWidth
    >
      <Box p={2} className={classes.boxContent}>
        {close && (
          <Box
            width={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            onClick={
              changeVisibility
                ? () => changeVisibility()
                : () => {
                    redirect !== '' && redirect();
                  }
            }
          >
            {typeModal('close')}
          </Box>
        )}
        {type
          ? typeModal(type)
          : Icon && (
              <Box display="flex" justifyContent="center">
                <Icon className={classes.icon} />{' '}
              </Box>
            )}

        {helpText && (
          <Box display="flex" justifyContent="center">
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.helpText}
            >
              {helpText}
            </Typography>
          </Box>
        )}

        {message && (
          <Box
            width={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box mb={2}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.fontWeightBold}
              >
                {message}
              </Typography>
            </Box>
            {!close && (
              <Button
                className={classes.button}
                onClick={() => changeVisibility()}
              >
                Aceptar
              </Button>
            )}
          </Box>
        )}
        {children}
        {actionButtons && (
          <Box display="flex" justifyContent="space-around">
            {_.map(actionButtons, (button) => (
              <Button
                key={_.uniqueId()}
                background
                onClick={
                  button.close
                    ? () => {
                        button.action();
                      }
                    : button.action
                }
                className={classes.button}
              >
                {button.title}
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
Modal.defaultProps = {
  modal: { close: true },
  size: 'md',
  actionButtons: undefined
};

Modal.propTypes = {
  modal: PropTypes.object,
  actions: PropTypes.object,
  children: PropTypes.node,
  size: PropTypes.string,
  actionButtons: PropTypes.array,
  helpText: PropTypes.string
};

export { Modal };
