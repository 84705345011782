/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  titleMetrics: {
    color: theme.palette.primary.main,
    fontSize: '22px !important'
  },
  select: {
    borderRadius: '50px',
    '& .MuiFilledInput-root ': {
      borderRadius: '50px',
      backgroundColor: 'white',
      border: '0px',
      textTransform: 'uppercase !important'
    },
    '& .MuiFilledInput-root:before': {
      border: 'none !important'
    },
    '& .MuiFilledInput-root:after': {
      border: 'none !important'
    }
  },
  mayuscles: {
    textTransform: 'uppercase !important'
  }
}));

export { useStyles };
