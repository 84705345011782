/* eslint-disable no-undef */
import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

const FullScreneLayout = ({ children }) => {
  return <Grid>{children}</Grid>;
};

FullScreneLayout.propTypes = {
  children: PropTypes.object
};
export { FullScreneLayout };
