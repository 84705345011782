/* eslint-disable no-undef */
import React, { useEffect, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './CuotesPaymentStyles';
import { CardBorder } from '../CardBorder/Index';
import { CuotesPaymentContext } from '../../Context/CuotesPayment';
import { Filter } from '../Filter';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip } from 'recharts';
import _ from 'lodash';

const CuotesPayment = () => {
  const classes = useStyles();
  const { getCharts, listChart, timePaimentChart } =
    useContext(CuotesPaymentContext);
  const [chartEngament, setchartEngament] = useState(null);

  useEffect(() => {
    setchartEngament({
      series: [
        _.get(listChart, ['payForeign'], 0),
        _.get(listChart, ['payInApp'], 0)
      ],
      options: {
        chart: {
          width: 380,
          type: 'pie'
        },
        legend: {
          position: 'bottom'
        },
        colors: ['#FC7B09', '#EA285D'],
        labels: ['Pagos fuera de la app', 'Pagos en la app'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    });
  }, [listChart]);
  const parseDomain = (data01) => [
    0,
    Math.max(
      Math.max.apply(
        null,
        data01.map((entry) => entry.value)
      )
    )
  ];
  const range = [0, 100];
  const days = {
    Sunday: 'Domingo',
    Monday: 'Lunes',
    Tuesday: 'Martes',
    Wednesday: 'Miércoles',
    Thursday: 'Jueves',
    Friday: 'Viernes',
    Saturday: 'Sábado'
  };

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter financeFunc={(finance) => getCharts(finance)} title="CRÉDITO" />
      </Grid>
      <Grid container spacing={8}>
        <Grid item md={12}>
          <Typography className={classes.title}>
            CRÉDITO - PAGO DE CUOTAS
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <CardBorder title="TIEMPO DE PAGO PROMEDIO PREVIO A VENCIMIENTO">
            {timePaimentChart !== null &&
              Object.keys(_.get(timePaimentChart, ['dataChart'], [])).map(
                (keyChart) => (
                  <ScatterChart
                    width={800}
                    height={60}
                    margin={{
                      top: 10,
                      right: 0,
                      bottom: 0,
                      left: 0
                    }}
                    key={days[keyChart]}
                  >
                    <XAxis
                      type="category"
                      dataKey="hour"
                      interval={0}
                      tick={{ fontSize: 0 }}
                      tickLine={{ transform: 'translate(0, -6)' }}
                    />
                    <YAxis
                      type="number"
                      dataKey="index"
                      name={days[keyChart]}
                      height={10}
                      width={80}
                      tick={false}
                      tickLine={false}
                      axisLine={false}
                      label={{ value: days[keyChart], position: 'insideRight' }}
                    />
                    <ZAxis
                      type="number"
                      dataKey="value"
                      domain={parseDomain(timePaimentChart.dataChart[keyChart])}
                      range={range}
                    />
                    <Tooltip
                      cursor={{ strokeDasharray: '3 3' }}
                      wrapperStyle={{ zIndex: 100 }}
                    />
                    <Scatter
                      data={timePaimentChart.dataChart[keyChart]}
                      fill="#EA285D"
                    />
                  </ScatterChart>
                )
              )}
          </CardBorder>
        </Grid>
        <Grid item md={4} xs={12}>
          <CardBorder title="MÉTODO DE PAGO">
            {chartEngament !== null && (
              <Chart
                options={chartEngament.options}
                series={chartEngament.series}
                type="pie"
              />
            )}
          </CardBorder>
        </Grid>
      </Grid>
    </Grid>
  );
};

CuotesPayment.propTypes = {
  active: PropTypes.object,
  payload: PropTypes.object
};

export { CuotesPayment };
