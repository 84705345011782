/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { UsersAdminContext } from './UsersAdminContext';
import LogginReducer from './UsersAdminReducer';
import { useApi } from '../../Hooks';
import { LIST_USERS, GET_ROLES } from '../Types';

const UsersAdminState = (props) => {
  const initialState = {
    listUsers: null,
    responseUpload: null,
    listRoles: null
  };

  const [postFiles] = useApi({
    endpoint: 'api/v1/dashboard/user',
    method: 'get'
  });

  const [getAllRoles] = useApi({
    endpoint: 'api/v1/dashboard/roles',
    method: 'get'
  });

  const [createUserPost] = useApi({
    endpoint: 'api/v1/dashboard/user',
    method: 'post'
  });
  const [deleteOneUser] = useApi({
    endpoint: 'api/v1/dashboard/user',
    method: 'delete'
  });
  const [updatenUser] = useApi({
    endpoint: 'api/v1/dashboard/user',
    method: 'put'
  });

  const [state, dispatch] = useReducer(LogginReducer, initialState);

  const getUsers = async (files) => {
    const response = await postFiles({
      body: files,
      url: process.env.REACT_APP_BACKEND_URL
    });
    dispatch({ type: LIST_USERS, payload: response.data });
  };

  const getRoles = async () => {
    const response = await getAllRoles({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL
    });
    dispatch({ type: GET_ROLES, payload: response.data });
  };

  const createNewUser = async (userData) => {
    const response = await createUserPost({
      body: userData,
      message: true,
      url: process.env.REACT_APP_BACKEND_URL
    });

    if (response.message === 'Usuario creado con éxito')
      setTimeout(() => getUsers(), 2000);
  };

  const deleteUser = async (idUser) => {
    const response = await deleteOneUser({
      body: {},
      message: true,
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${idUser}`
    });
    if (response.status === 200) setTimeout(() => getUsers(), 2000);
  };

  const updateUser = async (dataUser) => {
    const { idUser } = dataUser;
    const response = await updatenUser({
      body: dataUser,
      message: true,
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${idUser}`
    });
    if (response.status === 200) setTimeout(() => getUsers(), 2000);
  };
  return (
    <UsersAdminContext.Provider
      value={{
        listUsers: state.listUsers,
        responseUpload: state.listUsers,
        getUsers,
        createNewUser,
        deleteUser,
        listRoles: state.listRoles,
        getRoles,
        updateUser
      }}
    >
      {props.children}
    </UsersAdminContext.Provider>
  );
};

export { UsersAdminState };
