/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './BehaviorStyles';
import { CardBorder } from '../CardBorder/Index';
import { Filter } from '../Filter';
import { BehaviorContext } from '../../Context/Behavior';
import Chart from 'react-apexcharts';

const Behavior = () => {
  const classes = useStyles();

  const { getActivitiChart, activityChart } = useContext(BehaviorContext);
  const [chartActivity, setchartActivity] = useState(null);

  useEffect(() => {
    if (activityChart !== null) {
      setchartActivity({
        series: [
          activityChart.activity.percentageRecord,
          activityChart.activity.percentageActivity,
          activityChart.activity.NumberOfLogins
        ],
        options: {
          chart: {
            height: 500,
            type: 'radialBar'
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: '22px'
                },
                value: {
                  fontSize: '16px'
                },
                total: {
                  show: false,
                  label: 'Total',
                  formatter: function () {
                    return (
                      activityChart.activity.percentageRecord +
                      activityChart.activity.percentageActivity +
                      activityChart.activity.NumberOfLogins
                    );
                  }
                }
              }
            }
          },
          labels: [
            'Porcentaje Record',
            'Porcentaje de actividad',
            'Numero de inicios de sesión'
          ]
        }
      });
    }
  }, [activityChart]);

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter
          title="MÉTRICAS GENERALES"
          financeFunc={(finance) => getActivitiChart(finance)}
        />
      </Grid>
      <Grid container spacing={8}>
        <Grid item md={12} xs={12}>
          <CardBorder title="ACTIVIDAD">
            {chartActivity !== null && (
              <Chart
                options={chartActivity.options}
                series={chartActivity.series}
                type="radialBar"
              />
            )}
          </CardBorder>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { Behavior };
