/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  input: {},
  mainContainer: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '50px'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '13px !important',
    margin: '6px !important',
    '& :hover': {
      color: theme.palette.primary.main
    },
    height: '74px',
    marginLeft: '25px ',
    width: '74px'
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '30px !important'
  },
  label: { fontSize: '14px !important', fontWeight: '800!important' }
}));

export { useStyles };
