/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';
const optionTitleTab = (theme) => ({
  fontSize: '25px !important',
  color: theme.palette.primary.main,
  fontWeight: '800 !important',
  cursor: 'pointer',
  marginTop: '2vh !important',
  textTransform: 'uppercase'
});

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  titleMetrics: {
    color: theme.palette.primary.main,
    fontSize: '22px !important'
  },
  containerSelector: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  titleSelector: {
    textAlign: 'center',
    fontSize: '14px  !important',
    fontWeight: '800 !important'
  },
  userEmail: {
    fontSize: '50px !important ',
    marginTop: '5vh !important',
    fontWeight: '800 !important',
    color: theme.palette.primary.main
  },
  changePassword: {
    fontSize: '20px !important ',
    marginTop: '1vh !important',
    fontWeight: '800 !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  titleOptionDisabled: {
    textDecoration: 'none',
    ...optionTitleTab(theme)
  },
  titleOption: {
    textDecoration: 'underline',
    ...optionTitleTab(theme)
  },
  centerButtom: {
    display: 'flex',
    justifyContent: 'center'
  },
  addButton: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: '45px !important'
  },
  addiconContent: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  contentModal: {
    textAlign: 'center',
    marginBottom: '2vh'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '10px'
  }
}));

export { useStyles };
