/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useStyles } from './DetailFinanceStyles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Input } from '../../Input';
import { Select } from '../../Select';

const DetailFinance = ({ financeDetail, updateFinance, closeDetail, type }) => {
  const classes = useStyles();
  const [selecApp, setselecApp] = useState(
    get(financeDetail, 'mobileApp', false)
  );
  const itemSelect = [
    { label: 'Con app', value: true },
    { label: 'Sin app', value: false }
  ];

  const validationSchema = yup.object({
    mobileApp: yup.string('se requiere una descripcciòn con letras'),
    name: yup
      .string('Se requiere un correo con letras')
      .required('El titulo es un campo obligatorio')
  });
  const formik = useFormik({
    initialValues: {
      name: financeDetail ? financeDetail.name : '',
      mobileApp: financeDetail ? financeDetail.description : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      await updateFinance({
        ...values,
        idfinance: financeDetail._id,
        mobileApp: selecApp ? selecApp : false
      });
      closeDetail();
    }
  });

  return (
    <Grid container>
      <Grid
        md={type !== 'createVideo' ? 11 : 12}
        className={classes.mainContainer}
      >
        <form
          className={classes.form}
          key="Login"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <Grid container>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item md={6} xs={6}>
                <Input
                  placeholder="Titulo"
                  label="Titulo"
                  type="text"
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  helperText={formik.touched.name && formik.touched.name}
                />
              </Grid>
              {console.log('formik', formik)}
              <Grid item md={6} xs={6}>
                <Select
                  options={itemSelect}
                  label="App Movil"
                  onChange={(e) => setselecApp(e.target.value)}
                  value={selecApp}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={10} md={9} className={classes.containerButton}>
                  <Button
                    fullWidth
                    type="submit"
                    className={classes.buttonSend}
                  >
                    {get(financeDetail, '_id', false)
                      ? 'Actualizar'
                      : 'Agregar'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

DetailFinance.propTypes = {
  financeDetail: PropTypes.object,
  listCurses: PropTypes.object,
  updateFinance: PropTypes.func,
  closeDetail: PropTypes.func,
  type: PropTypes.string
};
export { DetailFinance };
