/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { FinancesContext } from './FinanceContext';
import FinancesReducer from './FinanceReducer';

import { useApi } from '../../Hooks';
import { GET_FINANCES } from '../Types';

const FinanceState = (props) => {
  const initialState = {
    listFinances: null
  };

  const [getallFinances] = useApi({
    endpoint: 'api/v1/dashboard/companies',
    method: 'get'
  });

  const [updateFinances] = useApi({
    endpoint: 'api/v1/dashboard/companies',
    method: 'put'
  });

  const [addFinance] = useApi({
    endpoint: 'api/v1/dashboard/companies',
    method: 'post'
  });

  const [state, dispatch] = useReducer(FinancesReducer, initialState);

  const getFinances = async () => {
    const response = await getallFinances({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL
    });
    dispatch({ type: GET_FINANCES, payload: response.data });
  };
  const updateFinance = async (values) => {
    const { idfinance, type = 'null' } = values;
    let response;
    if (type === 'delete') {
      response = await updateFinances({
        body: { Active: values.status },
        url: process.env.REACT_APP_BACKEND_URL,
        message: true,
        urlParams: `/${idfinance}`
      });
    } else {
      response = await updateFinances({
        body: values,
        url: process.env.REACT_APP_BACKEND_URL,
        message: true,
        urlParams: `/${idfinance}`
      });
    }
    setTimeout(async () => {
      if (response.message.includes('Actualizada')) getFinances();
    }, 2000);
  };

  const createFinance = async (dataCurse) => {
    const response = await addFinance({
      body: dataCurse,
      url: process.env.REACT_APP_BACKEND_URL,
      message: true
    });
    setTimeout(async () => {
      if (response.message.includes('correctamente')) getFinances();
    }, 2000);
  };

  return (
    <FinancesContext.Provider
      value={{
        listFinances: state.listFinances,
        getallFinances: getFinances,
        updateFinance,
        createFinance
      }}
    >
      {props.children}
    </FinancesContext.Provider>
  );
};

export { FinanceState };
