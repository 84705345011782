/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  titleMetrics: {
    color: theme.palette.primary.main,
    fontSize: '22px !important'
  },
  containerSelector: {
    display: 'flex',
    flexDirection: 'column !important',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  titleSelector: {
    textAlign: 'center',
    fontSize: '14px  !important',
    fontWeight: '800 !important'
  },
  userEmail: {
    fontSize: '50px !important ',
    marginTop: '5vh !important',
    fontWeight: '800 !important',
    color: theme.palette.primary.main
  },
  changePassword: {
    fontSize: '20px !important ',
    marginTop: '1vh !important',
    fontWeight: '800 !important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  contentTable: {
    fontSize: '18px !important'
  },
  iconButton: {
    backgroundColor: 'white !important',
    padding: '7px !important',
    margin: '0px !important',
    '& :hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('lg')]: {
      padding: '7px !important',
      margin: '6px !important'
    }
  },
  contentButton: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: '#EA285D',
    fontSize: '30px !important'
  },
  label: { fontSize: '20px !important', fontWeight: '800!important' },

  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  centerButton: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5vh'
  },
  imagen: {
    filter:
      'invert(60%) sepia(76%) saturate(5789%) hue-rotate(336deg) brightness(94%) contrast(100%)',
    padding: '1.5vh !important ',
    height: '7vh',
    width: '7vh'
  }
}));

export { useStyles };
