import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    height: 'auto',
    margin: '3rem 1rem',
    overflow: 'hidden',
    backgroundColor: 'white',
    borderRadius: '52px',
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  titleLink: {
    color: '#EA285D',
    fontSize: '18px',
    fontWeight: '800 !important'
  }
}));

export { useStyles };
