/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';
import { Theme, CSSObject } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    background:
      'transparent linear-gradient(180deg, #EA285D 0%, #FE8203 100%) 0% 0% no-repeat padding-box',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    color: theme.pallete
  },
  itemActive: {
    backgroundColor: '#F3EFE7',
    borderRadius: '50px 0px 0px 50px',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    margin: '3vh 0vh !important',
    padding: '2vh !important',
    width: '100%'
  },
  itemDisabled: {
    cursor: 'pointer !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    margin: '3vh 0vh !important',
    padding: '2vh !important',
    [theme.breakpoints.down('sm')]: {
      justifyContent: ''
    }
  },
  iconActive: {
    cursor: 'pointer !important',
    filter:
      'invert(60%) sepia(76%) saturate(5789%) hue-rotate(336deg) brightness(94%) contrast(100%)',
    height: '3vh'
  },
  iconDisabled: {
    cursor: 'pointer !important',
    height: '3vh'
  },
  iconDisabledNotification: {
    fontSize: '40px !important',
    cursor: 'pointer',
    color: '#F3EFE7',
    marginLeft: '10px',
    height: '30px'
  },
  iconLogout: {
    fontSize: '20px !important',
    cursor: 'pointer',
    color: 'white'
  },
  logoutDiv: {
    borderRadius: '50%',
    padding: '15px',
    marginLeft: '-1rem',
    marginTop: '3rem',
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      padding: '10px'
    }
  },
  drawer: {
    '& .MuiPaper-root': {
      background:
        'transparent linear-gradient(180deg, #EA285D 0%, #FE8203 100%) 0% 0% no-repeat padding-box'
    }
  },
  containerButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  avatar: {
    backgroundColor: 'white !important',
    padding: '0.5rem',
    cursor: 'pointer'
  },
  containerContent: {
    overflow: 'hidden',
    backgroundColor: '#F2ECE4',
    padding: '2vh',
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '2vh !important'
    }
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,

  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});
export { useStyles, openedMixin, closedMixin };
