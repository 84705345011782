import LogoutIcon from './apagar.png';
import HomeIcon from './casa1.png';
import DataBaseIcon from './d1.png';
import MovileIcon from './m1.png';
import UserIcon from './usuaria2.png';
import MovieIcon from './videoicon.png';
import Mapa from './mapa.png';
import NotificationIcon from './notificationIcon.png';
import LogoAda from './Logo_Degradado.png';
import FilterIcon from './filtro_02.png';

const Images = {
  LogoutIcon,
  HomeIcon,
  DataBaseIcon,
  MovileIcon,
  UserIcon,
  MovieIcon,
  Mapa,
  NotificationIcon,
  LogoAda,
  FilterIcon
};

export { Images };
