/* eslint-disable no-undef */
import React, { useEffect, useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './CreditCalendarStyles';
import { CardBorder } from '../CardBorder/Index';
import { CreditCalendarContext } from '../../Context/CreditCalendar';
import { Filter } from '../Filter';
import Chart from 'react-apexcharts';

const CreditCalendar = () => {
  const classes = useStyles();
  const { getChartWeekly, weeklyChart } = useContext(CreditCalendarContext);
  const [chartEngament, setchartEngament] = useState(null);

  useEffect(() => {
    if (weeklyChart !== null)
      setchartEngament({
        series: [
          {
            name: 'Usuarios',
            data: weeklyChart.result.map(
              (itemWeeckly) => itemWeeckly.totalEvents
            )
          }
        ],
        options: {
          chart: {
            height: 550,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          colors: ['#FC7B09'],
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: '',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: weeklyChart.result.map(
              (itemWeeckly) =>
                `${itemWeeckly.cycleDateRange.start}-${itemWeeckly.cycleDateRange.end}`
            )
          }
        }
      });
  }, [weeklyChart]);

  return (
    <Grid className={classes.rootContainer}>
      <Grid container>
        <Filter
          financeFunc={(finance) => getChartWeekly(finance)}
          title="CRÉDITO"
        />
      </Grid>
      <Grid container spacing={8}>
        <Grid item md={12}>
          <Typography className={classes.title}>
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => (window.location = 'Credit')}
            >
              CRÉDITO
            </span>{' '}
            - CALENDARIO
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <CardBorder title="CHEQUEOS SEMANALES POR USUARIA">
            {chartEngament !== null && (
              <Chart
                options={chartEngament.options}
                series={chartEngament.series}
                type="line"
              />
            )}
          </CardBorder>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { CreditCalendar };
