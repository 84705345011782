/* eslint-disable no-undef */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat'
  },
  button: {
    backgroundColor: '#EA285D !important',
    '&.MuiButton-root': {
      color: 'white !important',
      padding: '25px 38px',
      borderRadius: '50px',
      marginTop: '2vh'
    }
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export { useStyles };
