/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { ClientsContext } from './ClientsContext';
import ClientsReducer from './ClientsReducer';

import { useApi } from '../../Hooks';
import { LIST_CLIENTS, CLIENT_DETAIL } from '../Types';

const ClientsState = (props) => {
  const initialState = {
    listclients: null,
    infoClient: null
  };

  const [getallClients] = useApi({
    endpoint: 'api/v1/dashboard/clients',
    method: 'get'
  });

  const [state, dispatch] = useReducer(ClientsReducer, initialState);

  const getclients = async (finance) => {
    const response = await getallClients({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${finance}/date`
    });
    dispatch({ type: LIST_CLIENTS, payload: response.data });
  };
  const getclientDetail = async (infoClient) => {
    const response = await getallClients({
      body: {},
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `/${infoClient.finance}/details/${infoClient.id}`
    });
    dispatch({ type: CLIENT_DETAIL, payload: response.data });
  };

  return (
    <ClientsContext.Provider
      value={{
        listclients: state.listclients,
        infoClient: state.infoClient,
        getclients,
        getclientDetail
      }}
    >
      {props.children}
    </ClientsContext.Provider>
  );
};

export { ClientsState };
