/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import { CreditCalendarContext } from './CreditCalendarContext';
import CreditCalendarReducer from './CreditCalendarReducer';

import { useApi } from '../../Hooks';
import { WEEKLY_CHART } from '../Types';

const CreditCalendarState = (props) => {
  const initialState = {
    weeklyChart: null
  };

  const [getDataAnalitycs] = useApi({
    endpoint: 'api/v1/dashboard/clients/',
    method: 'get'
  });

  const [state, dispatch] = useReducer(CreditCalendarReducer, initialState);

  const getChartWeekly = async (financiera) => {
    const response = await getDataAnalitycs({
      body: {},
      message: false,
      url: process.env.REACT_APP_BACKEND_URL,
      urlParams: `${financiera}/graphics-calendar`
    });
    dispatch({ type: WEEKLY_CHART, payload: response.data });
  };

  return (
    <CreditCalendarContext.Provider
      value={{
        weeklyChart: state.weeklyChart,
        getChartWeekly
      }}
    >
      {props.children}
    </CreditCalendarContext.Provider>
  );
};

export { CreditCalendarState };
