import { LIST_CLIENTS, CLIENT_DETAIL } from '../Types';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case LIST_CLIENTS:
      return {
        ...state,
        listclients: payload
      };
    case CLIENT_DETAIL:
      return {
        ...state,
        infoClient: payload
      };
    default:
      return state;
  }
};
